import cn from 'classnames';
import dayjs from 'dayjs';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { History } from 'history';
import React, { Suspense, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { PATHS } from 'src/appConfig/paths';
import { Button, Checkbox, Grid, NavLink, Select, Text, View } from 'src/components/common';
import { useSignUp } from 'src/queries';
import { hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
import { IRootState } from 'src/redux/rootReducer';
import { ErrorService, Navigator, Toastify } from 'src/services';
import { deepKeys, scrollToTopError } from 'src/utils';
import { DateFormat } from 'src/utils/momentUtils';
import { isEmpty } from 'src/validations';
import { UAMBody } from '../../common';
import EmailConfirmationModal from '../../common/EmailConfirmationModal';
import AdjusterTypeCheck from './AdjusterTypeCheck';
import LegalGuardianQuestion from './LegalGuardianQuestion';
import ThirdPartyConfirm from './ThirdPartyConfirm';
import {
  OptionType,
  SignUpFormKey,
  SignupFormValues,
  USER_CLASSIFICATION,
  USER_TYPE,
  initialSignupFormValues,
  isContain,
  isParentArray,
  programTypeOptions,
  signupFormValidationSchema,
  toArrayValue,
  userClassificationOptions,
  userTypeOptions,
} from './helpers';
import configs from 'src/appConfig';
import ReCAPTCHA from 'react-google-recaptcha';

const EmployeeQuestion = React.lazy(() => import('./EmployeeQuestion'));
const AdjusterQuestion = React.lazy(() => import('./AdjusterQuestion'));
const CarrierQuestion = React.lazy(() => import('./CarrierQuestion'));
const ContractorQuestion = React.lazy(() => import('./ContractorQuestion'));
const DependentQuestion = React.lazy(() => import('./DependentQuestion'));
const GovernmentAgencyQuestion = React.lazy(() => import('./GovernmentAgencyQuestion'));
const EmployerQuestion = React.lazy(() => import('./EmployerQuestion'));
const PhysicianQuestion = React.lazy(() => import('./PhysicianQuestion'));
const RepresentativeQuestion = React.lazy(() => import('./RepresentativeQuestion'));
const ThirdPartyQuestion = React.lazy(() => import('./ThirdPartyQuestion'));

const EmployerSignUp: React.FC<Props> = ({ onShowDialog, onHideDialog }) => {
  const formRef = React.useRef<FormikProps<SignupFormValues>>(null);
  const [userClassification, setUserClassification] = useState([]);
  const [userType, setUserType] = useState([]);
  const reRef = useRef<ReCAPTCHA>();

  const getInitialFormValues = () => {
    return {
      ...initialSignupFormValues,
    };
  };

  const { signup, isSigningUp } = useSignUp({
    onSuccess(data, variables, context) {
      onShowDialog({
        type: DIALOG_TYPES.CONTENT_DIALOG,
        data: {
          content: (
            <EmailConfirmationModal
              username={
                variables?.userType === USER_TYPE.DEPENDENT ||
                variables?.userType === USER_TYPE.SPOUSE
                  ? variables.dsEmailAddress
                  : variables.email
              }
              onConfirmSuccess={() => {
                Navigator.navigate(PATHS.signIn, {
                  email:
                    variables?.userType === USER_TYPE.DEPENDENT ||
                    variables?.userType === USER_TYPE.SPOUSE
                      ? variables.dsEmailAddress
                      : variables.email,
                });
                Toastify.success('Sign up successfully');
                onHideDialog();
              }}
            />
          ),
          hideTitle: true,
        },
      });
    },
    onError(error, variables, context) {
      handleError(error);
      handleScrollToTopError();
    },
  });

  const handleSubmit = async (values: SignupFormValues) => {
    const recaptchaToken = await reRef.current.executeAsync();
    reRef.current.reset();

    const payload = {
      ...values,
      recaptchaToken,
      email:
        values?.userType === USER_TYPE.DEPENDENT || values?.userType === USER_TYPE.SPOUSE
          ? values.dsEmailAddress
          : values.email,
      programType: toArrayValue(values?.programType).join(';'),
      dob: values?.dob ? dayjs(values?.dob).format(DateFormat) : '',
      phoneNumber: values?.phoneNumber || values?.contactNumber,
      userType: userType.find(({ value }) => value === values?.userType).label,
      userClassification: userClassification.find(
        ({ value }) => value === values?.userClassification
      ).label,
      islandOfService: toArrayValue(values?.islandOfService).join(';'),
      eofName: values?.eofName || values?.insuranceCarrierName,
      registrationExpirationDate:
        values?.registrationExpirationDate &&
        dayjs(values?.registrationExpirationDate).format(DateFormat),
      dsBirthDate: values?.dsBirthDate ? dayjs(values?.dsBirthDate).format(DateFormat) : '',
    };

    signup(payload);
  };

  const handleError = (error: AuthError) => {
    switch (error.code) {
      case ErrorService.TYPES.InvalidPasswordException:
        return formRef.current.setErrors({ password: error.message });

      case ErrorService.TYPES.UsernameExistsException:
        return formRef.current.setErrors({ email: error.message });

      case ErrorService.TYPES.NotAuthorizedException:
        return ErrorService.handler(error);

      default:
        return ErrorService.handler(error);
    }
  };

  const handleScrollToTopError = () => {
    setTimeout(() => {
      scrollToTopError(deepKeys(formRef?.current?.errors));
    }, 100);
  };

  const validate = (values: SignupFormValues) => {
    let errors: FormikErrors<SignupFormValues> = {
      confirmPassword: undefined,
    };

    if (values?.password !== values?.confirmPassword) {
      errors.confirmPassword = 'Password mismatch'; // pragma: allowlist secret
    } else {
      delete errors.confirmPassword;
    }

    return errors;
  };

  return (
    <Suspense fallback={null}>
      <UAMBody>
        <Text size={36} className={cn('fw-medium mb-8 text-color-grey-900 text-align-center')}>
          Sign Up
        </Text>

        <Formik<SignupFormValues>
          initialValues={getInitialFormValues()}
          onSubmit={handleSubmit}
          validationSchema={signupFormValidationSchema}
          innerRef={formRef}
          validate={validate}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            getFieldProps,
            handleSubmit,
            resetForm,
            setFieldTouched,
          }) => {
            const isUserType = (userType: USER_TYPE) => values?.userType === userType;
            const isUserClassification = (userClassification: USER_CLASSIFICATION) =>
              values?.userClassification === userClassification;

            const getErrorMessage = (fieldName: SignUpFormKey) => {
              // eslint-disable-next-line security/detect-object-injection
              return touched[fieldName] && errors[fieldName] ? (errors[fieldName] as string) : '';
            };

            const handleChangeProgramType = (name: SignUpFormKey, value: OptionType[]) => {
              resetForm({
                values: { ...initialSignupFormValues },
              });
              setFieldValue(name, value);
              const userClassificationOption = userClassificationOptions.map((option) => ({
                ...option,
                isHidden:
                  isContain(toArrayValue(value), option.notInProgramTypeCondition) ||
                  (!!option?.programTypeCondition &&
                    isParentArray(toArrayValue(value), option?.programTypeCondition)),
              }));
              setUserClassification(userClassificationOption);
            };

            const handleChangeUserClassification = (name: SignUpFormKey, value: string) => {
              resetForm({
                values: { ...initialSignupFormValues, programType: values?.programType },
              });
              setFieldValue(name, value);
              setUserType(
                userTypeOptions.map((option) => ({
                  ...option,
                  isHidden:
                    !isContain([value], option.userClassificationCondition) ||
                    (option?.programTypeCondition &&
                      isContain(toArrayValue(values?.programType), option?.programTypeCondition)),
                }))
              );
            };

            const handleChangeUserType = (name: SignUpFormKey, value: string) => {
              resetForm({
                values: {
                  ...initialSignupFormValues,
                  userClassification: values?.userClassification,
                  programType: values?.programType,
                },
              });
              setFieldValue(name, value);
              setFieldValue(
                SignUpFormKey.PORTAL,
                userType.find((type) => type.value === value).portal
              );
            };

            const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, value } = e.target;
              setFieldValue(name, value.toUpperCase());
            };

            return (
              <>
                <View className="ctn-uam__form">
                  <Grid.Wrap>
                    <>
                      <Grid.Item variant="is-full">
                        <Select
                          label={'Program Type'}
                          options={programTypeOptions}
                          errorMessage={getErrorMessage(SignUpFormKey.PROGRAM_TYPE)}
                          {...getFieldProps(SignUpFormKey.PROGRAM_TYPE)}
                          onChange={handleChangeProgramType}
                          isClearable={false}
                          required
                          isMulti
                          placeholder="Select all that apply"
                        />
                      </Grid.Item>
                      <Grid.Item variant="is-full">
                        <Select
                          label={'User Classification'}
                          options={userClassification}
                          errorMessage={getErrorMessage(SignUpFormKey.USER_CLASSIFICATION)}
                          {...getFieldProps(SignUpFormKey.USER_CLASSIFICATION)}
                          onChange={handleChangeUserClassification}
                          isClearable={false}
                          required
                          isDisabled={isEmpty(values?.programType)}
                        />
                      </Grid.Item>
                      <Grid.Item variant="is-full">
                        <Select
                          label={'User Type'}
                          options={userType}
                          errorMessage={getErrorMessage(SignUpFormKey.USER_TYPE)}
                          {...getFieldProps(SignUpFormKey.USER_TYPE)}
                          onChange={handleChangeUserType}
                          isClearable={false}
                          required
                          isDisabled={isEmpty(values?.userClassification)}
                        />
                      </Grid.Item>
                    </>
                    <Suspense fallback={null}>
                      {(isUserType(USER_TYPE.DEPENDENT) || isUserType(USER_TYPE.SPOUSE)) && (
                        <DependentQuestion
                          getFieldProps={getFieldProps}
                          values={values}
                          getErrorMessage={getErrorMessage}
                          setFieldValue={setFieldValue}
                        />
                      )}
                      {(isUserType(USER_TYPE.DEPENDENT) ||
                        isUserType(USER_TYPE.SPOUSE) ||
                        isUserType(USER_TYPE.EMPLOYEE)) && (
                        <EmployeeQuestion
                          getFieldProps={getFieldProps}
                          values={values}
                          getErrorMessage={getErrorMessage}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          handleChangeState={handleChangeState}
                        />
                      )}
                      {((isUserClassification(USER_CLASSIFICATION.EMPLOYER) && values?.userType) ||
                        isUserType(USER_TYPE.SELF_INSURED)) && (
                        <EmployerQuestion
                          getFieldProps={getFieldProps}
                          values={values}
                          getErrorMessage={getErrorMessage}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          handleChangeState={handleChangeState}
                        />
                      )}
                      {isUserType(USER_TYPE.CARRIER) && (
                        <CarrierQuestion
                          getFieldProps={getFieldProps}
                          values={values}
                          getErrorMessage={getErrorMessage}
                          setFieldValue={setFieldValue}
                          handleChangeState={handleChangeState}
                        />
                      )}
                      {isUserType(USER_TYPE.THIRD_PARTY_ADMIN) && (
                        <ThirdPartyQuestion
                          getFieldProps={getFieldProps}
                          values={values}
                          getErrorMessage={getErrorMessage}
                          setFieldValue={setFieldValue}
                          handleChangeState={handleChangeState}
                        />
                      )}
                      {isUserClassification(USER_CLASSIFICATION.HEALTH_CARE_PROVIDER) &&
                        values?.userType && (
                          <PhysicianQuestion
                            getFieldProps={getFieldProps}
                            values={values}
                            getErrorMessage={getErrorMessage}
                            setFieldValue={setFieldValue}
                          />
                        )}
                      {(isUserType(USER_TYPE.BILL_REVIEW) ||
                        isUserType(USER_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR) ||
                        isUserClassification(USER_CLASSIFICATION.LEGAL)) &&
                        values?.userType && (
                          <RepresentativeQuestion
                            getFieldProps={getFieldProps}
                            values={values}
                            getErrorMessage={getErrorMessage}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            handleChangeState={handleChangeState}
                          />
                        )}
                      {isUserType(USER_TYPE.LEGAL_GUARDIAN) && (
                        <LegalGuardianQuestion
                          getFieldProps={getFieldProps}
                          values={values}
                          getErrorMessage={getErrorMessage}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          handleChangeState={handleChangeState}
                        />
                      )}
                      {isUserType(USER_TYPE.CONTRACTOR) && (
                        <ContractorQuestion
                          setFieldValue={setFieldValue}
                          getFieldProps={getFieldProps}
                          values={values}
                          getErrorMessage={getErrorMessage}
                          handleChangeState={handleChangeState}
                        />
                      )}
                      {isUserClassification(USER_CLASSIFICATION.GOVERNMENT_AGENCY) && (
                        <GovernmentAgencyQuestion
                          getFieldProps={getFieldProps}
                          values={values}
                          getErrorMessage={getErrorMessage}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          handleChangeState={handleChangeState}
                        />
                      )}
                      {isUserType(USER_TYPE.ADJUSTER) && (
                        <AdjusterQuestion
                          getFieldProps={getFieldProps}
                          values={values}
                          getErrorMessage={getErrorMessage}
                          setFieldValue={setFieldValue}
                          handleChangeState={handleChangeState}
                        />
                      )}
                      {(isUserType(USER_TYPE.EMPLOYER) ||
                        isUserType(USER_TYPE.PHYSICIAN) ||
                        isUserType(USER_TYPE.BILL_REVIEW) ||
                        isUserType(USER_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR) ||
                        isUserType(USER_TYPE.ADJUSTER) ||
                        isUserType(USER_TYPE.CARRIER)) && (
                        <ThirdPartyConfirm getFieldProps={getFieldProps} values={values} />
                      )}
                      {isUserType(USER_TYPE.ADJUSTER) &&
                        values[SignUpFormKey.IS_THIRD_PARTY_ADMIN] && (
                          <AdjusterTypeCheck
                            getErrorMessage={getErrorMessage}
                            getFieldProps={getFieldProps}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                          />
                        )}
                    </Suspense>
                    <Grid.Item variant="is-full">
                      <View align="center" isRowWrap>
                        <Checkbox.Item
                          {...getFieldProps(SignUpFormKey.IS_AGREEMENT)}
                          checked={values?.[SignUpFormKey.IS_AGREEMENT]}
                        />
                        <Text>
                          By submitting this request, I agree to abide by all laws and restrictions
                          governing the personal information of individuals and organizations
                          involved in a case.
                        </Text>
                      </View>
                    </Grid.Item>
                    <ReCAPTCHA sitekey={configs.RECAPTCHA_SITE_KEY} size="invisible" ref={reRef} />
                    <Grid.Item variant="is-full">
                      <View flexGrow={1}>
                        <Button
                          type="button"
                          variant="secondary"
                          className="my-2 fw-medium"
                          isLoading={isSigningUp}
                          disabled={!values[SignUpFormKey.IS_AGREEMENT]}
                          onClick={() => {
                            handleSubmit();
                            handleScrollToTopError();
                          }}
                        >
                          Register
                        </Button>
                      </View>
                    </Grid.Item>
                  </Grid.Wrap>
                </View>
              </>
            );
          }}
        </Formik>
        <Text className={cn('my-2 text-center')}>
          <NavLink className={'fw-medium text-is-16'} to={PATHS.signIn}>
            Back to Log In
          </NavLink>
        </Text>
      </UAMBody>
    </Suspense>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & { history: History };

const mapStateToProps = (state: IRootState) => ({
  // isSigningIn: state.auth.is,
  // error: state.auth.error,
});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerSignUp);
