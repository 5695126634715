import React from 'react';
import { TenantService } from 'src/services';
import EmployerSignUp from '../employer/Signup';
import { History } from 'history';
import CustomErrorBoundary from 'src/components/ErrorBoundary/CustomErrorBoundary';

const SignUp: React.FC<Props> = (props) => {
  if (TenantService.isEmployer())
    return (
      <CustomErrorBoundary showErrorMessage>
        <EmployerSignUp {...props} />
      </CustomErrorBoundary>
    );
  return null;
};

type Props = { history: History };

export default SignUp;
