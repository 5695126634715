import { FieldInputProps } from 'formik';
import React from 'react';
import { Grid, Input, InputMask, InputPhone, Select } from 'src/components/common';
import { Callback } from 'src/redux/types';
import EmailSection from '../EmailSection';
import {
  IDENTIFICATION_TYPE,
  SignUpFormKey,
  SignupFormValues,
  USER_TYPE,
  identificationTypeOptions,
} from '../helpers';
import SectionText from '../SectionText';

const PersonalInformation: React.FC<Props> = ({
  getFieldProps,
  values,
  getErrorMessage,
  emailLabel,
  setFieldValue,
  setFieldTouched,
}) => {
  const handleIdentificationTypeChange = (name, value) => {
    setFieldValue(name, value);
    setFieldValue(SignUpFormKey.IDENTIFICATION_NUMBER, '');
    setFieldTouched(SignUpFormKey.IDENTIFICATION_NUMBER, false);
  };

  return (
    <>
      <SectionText section="Personal Information" />
      <Grid.Item>
        <Input
          label={`Last Name`}
          required
          placeholder="Enter your Last Name"
          errorMessage={getErrorMessage(SignUpFormKey.LAST_NAME)}
          maxLength={40}
          {...getFieldProps(SignUpFormKey.LAST_NAME)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`First Name`}
          required
          placeholder="Enter your First Name"
          errorMessage={getErrorMessage(SignUpFormKey.FIRST_NAME)}
          maxLength={30}
          {...getFieldProps(SignUpFormKey.FIRST_NAME)}
        />
      </Grid.Item>
      <Grid.Item renderIf={values?.[SignUpFormKey.USER_TYPE] === USER_TYPE.LEGAL_GUARDIAN}>
        <Select
          label={'Identification Type'}
          options={identificationTypeOptions}
          errorMessage={getErrorMessage(SignUpFormKey.IDENTIFICATION_TYPE)}
          {...getFieldProps(SignUpFormKey.IDENTIFICATION_TYPE)}
          onChange={handleIdentificationTypeChange}
          isClearable={false}
          required
          onBlur={setFieldTouched}
        />
      </Grid.Item>
      <Grid.Item renderIf={values?.[SignUpFormKey.USER_TYPE] === USER_TYPE.LEGAL_GUARDIAN}>
        <InputMask
          label={`Identification Number`}
          mask={
            values[SignUpFormKey.IDENTIFICATION_TYPE] === IDENTIFICATION_TYPE.SOCIAL_SECURITY_NUMBER
              ? '999999999'
              : '***********'
          }
          placeholder="Enter your Identification Number"
          required
          disabled={!values[SignUpFormKey.IDENTIFICATION_TYPE]}
          errorMessage={getErrorMessage(SignUpFormKey.IDENTIFICATION_NUMBER)}
          {...getFieldProps(SignUpFormKey.IDENTIFICATION_NUMBER)}
        />
      </Grid.Item>
      <Grid.Item>
        <InputPhone
          international={false}
          label={`Contact Number`}
          required
          placeholder="Enter your Contact Number"
          errorMessage={getErrorMessage(SignUpFormKey.CONTACT_NUMBER)}
          {...getFieldProps(SignUpFormKey.CONTACT_NUMBER)}
          countries={['US']}
          onChange={setFieldValue}
        />
      </Grid.Item>
      <EmailSection
        getFieldProps={getFieldProps}
        values={values}
        getErrorMessage={getErrorMessage}
        emailLabel={emailLabel}
      />
    </>
  );
};

type Props = {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  values: SignupFormValues;
  getErrorMessage: (fieldName: SignUpFormKey) => string;
  emailLabel?: string;
  setFieldValue: Callback;
  setFieldTouched?: Callback;
};

export default PersonalInformation;
