import apisauce from 'apisauce';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import appConfig from 'src/appConfig';
import { ProfilePayload } from 'src/queries';
import {
  ChangePasswordPayload,
  CompleteNewPasswordPayload,
  ConfirmPasswordPayload,
  ConfirmSignInPayload,
  ConfirmSignUpPayload,
  ForgotPasswordPayload,
  ResendSignUpPayload,
  SignInPayload,
  SignUpPayload,
  SubmitForgotPasswordPayload,
} from 'src/queries/UAM/types';
import {
  formatPhoneNumber,
  newCancelToken,
  removeSpecialCharacterFromString,
  stringify,
} from 'src/utils';
import { isEmpty } from 'src/validations';
import { TokenService } from '.';
import { DirectDepositPayload, MailingAddressPayload } from './../queries/Profile/types';
axios.defaults.withCredentials = true;

const create = (baseURL = 'https://datahouse.com') => {
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
      Accept: 'application/json',
    },
    timeout: appConfig.CONNECTION_TIMEOUT,
  });

  api.axiosInstance.interceptors.request.use((config) => {
    return TokenService.getToken()
      .then((token) => {
        config.headers.Authorization = 'Bearer ' + token;
        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });
  const getRoot = () => api.get('');

  // ====================== Auth ======================
  const signIn = (body: SignInPayload) =>
    Auth.signIn(body.username, body.password, {
      recaptchaToken: body.recaptchaToken,
    });

  const signUp = (body: SignUpPayload) => {
    const params = {
      username: body.email,
      password: body.password,
    };

    const attributes = {
      given_name: body.firstName,
      family_name: body.lastName,
      middle_name: body.middleName,
      address: body.address,
      'custom:phoneNumber': formatPhoneNumber(body.phoneNumber),
      email: body.email,
      'custom:street': JSON.stringify({
        state: body.state,
        city: body.city,
        zipCode: body.zipCode,
        street: '',
      }),
      'custom:dateOfBirth': body.dob,
      'custom:mailingAddress': JSON.stringify({
        mailingAddress: body.mailingAddress,
        mailingCity: body.mailingCity,
        mailingState: body.mailingState,
        mailingZipCode: body.mailingZipCode,
      }),
      'custom:portal': body.portal,
      'custom:userType': JSON.stringify({
        userType: body.userType,
        userClassification: body.userClassification,
        programType: body.programType,
      }),
      'custom:ssn': JSON.stringify({
        ssn: '',
        idNumber: body.identificationNumber,
        identificationType: body.identificationType,
      }),
      'custom:organizationType': JSON.stringify({
        relationToClaimant: body.relationToClaimant,
        parentCompanyName: body.parentCompanyName,
        natureOfBusiness: body.natureOfBusiness,
        organizationType: body.organizationType,
        federalIDNumber: removeSpecialCharacterFromString(body.federalIDNumber),
      }),
      'custom:typeOfService': JSON.stringify({
        iCN: body.insuranceCarrierName,
        typeOfService: body.serviceType,
        islandsOfService: body.islandOfService,
        rED: body.registrationExpirationDate,
        hCCN: body.healthCareContractorName,
        hCC: body.healthCareContractor,
        agencyName: body.agencyName,
      }),
      'custom:adjustingServices': JSON.stringify({
        aSN: body.adjustingServicesName,
        adjusterId: body.adjusterId,
        adjustingServices: body.adjustingServices,
      }),
      'custom:admS': JSON.stringify({
        admSN: body.adminServicesName,
        admS: body.adminServices,
      }),
      'custom:eofName': JSON.stringify({
        eofName: body.eofName,
        eofPhysicianName: body.physicianName,
        eofPhoneNumber: !isEmpty(body.eofPhoneNumber)
          ? formatPhoneNumber(body.eofPhoneNumber)
          : !isEmpty(body.organizationContactNumber)
          ? formatPhoneNumber(body.organizationContactNumber)
          : '',
        eofZipCode: body.eofZipCode,
        eofStreet: body.eofStreet,
        eofCity: body.eofCity,
        eofState: body.eofState,
        eofAddress: body.eofAddress,
      }),
      'custom:eofMA': JSON.stringify({
        eofMA: body.eofMailingAddress,
        eofMS: body.eofMailingStreet,
        eofMC: body.eofMailingCity,
        eofMST: body.eofMailingState,
        eofMZA: body.eofMailingZipCode,
        eofIsThirdPartyAdmin: body.eofIsThirdPartyAdmin,
        eofSelfInsuredOrAdjustingCompany: body.eofSelfInsuredOrAdjustingCompany,
      }),
      'custom:dependentSpouse': JSON.stringify({
        dsFirstName: body.dsFirstName,
        dsLastName: body.dsLastName,
        dsMiddleName: body.dsMiddleName,
        dsPhoneNumber: formatPhoneNumber(body.dsPhoneNumber),
        dsBirthDate: body.dsBirthDate,
        dsEmailAddress: body.dsEmailAddress,
        dsIdNumber: removeSpecialCharacterFromString(body.dsIdNumber),
        claimantEmail: body.claimantEmail,
        claimantIdType: body.claimantIdType,
        claimantIdNumber: body.claimantIdNumber,
      }),
    };

    const validationData = {
      recaptchaToken: body.recaptchaToken,
    };

    return Auth.signUp({ ...params, validationData, attributes });
  };

  const resendSignUp = (body: ResendSignUpPayload) => Auth.resendSignUp(body.username);

  const confirmSignUp = (body: ConfirmSignUpPayload) =>
    Auth.confirmSignUp(body.username, body.code);

  const signOut = () => Auth.signOut();

  const forgotPassword = (body: ForgotPasswordPayload) => Auth.forgotPassword(body.email);

  const submitForgotPassword = (body: SubmitForgotPasswordPayload) =>
    Auth.forgotPasswordSubmit(body.email, body.token, body.password);

  const changePassword = (body: ChangePasswordPayload) =>
    Auth.changePassword(body.user, body.currentPassword, body.newPassword);

  const confirmSignIn = (body: ConfirmSignInPayload) =>
    Auth.sendCustomChallengeAnswer(body.user, body.code);

  const confirmPassword = (password: ConfirmPasswordPayload) => {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.signIn({
        username: user.username,
        password: password.password,
      })
    );
  };

  const completeNewPassword = (body: CompleteNewPasswordPayload) =>
    Auth.completeNewPassword(body.user, body.password, body.requiredAttributes);

  // ====================== Profile ======================
  const getUserId = (params: { username: string }) => {
    const username = { username: params.username };
    const queryString = stringify(username);
    return api.get(`/account-svc/v1/users/user-id?${queryString}`, {}, newCancelToken());
  };

  // ====================== Claimant Profile ======================
  const getMyProfile = () => api.get('/account-svc/v1/claimant/me', {}, newCancelToken());

  const updateUserAvatar = (body: { avatarUrl: string }) =>
    api.patch(`/me/avatar`, body, newCancelToken());

  const updateMyProfile = (body: ProfilePayload) =>
    api.put(`/account-svc/v1/claimant/me`, body, newCancelToken());
  const updateMailingAddress = (body: MailingAddressPayload) =>
    api.put(`/account-svc/v1/claimant/me/mailing-address`, body, newCancelToken());

  const updateDirectDeposit = (body: DirectDepositPayload) =>
    api.put(`account-svc/v1/claimant/me/direct-deposit`, body, newCancelToken());

  // ====================== Content ======================
  // const getContent = () => api.get('/content', {}, newCancelToken());
  const getContents = () => api.get('/account-svc/v1/contents', {}, newCancelToken());

  // ====================== File ======================
  // const getPresignedUserServiceUrl = (params: GetPresignedPayload) =>
  //   api.get('/files/presigned-upload-url', params, newCancelToken());
  // const uploadFile = (body: UploadFilePayload) => axios.put(body.url, body.fileData);

  const getDecodeUserServiceUrl = (params: { filePath: string }) =>
    api.get('/files/presigned-download-url', params, newCancelToken());

  // const uploadFileWithProgress = (body: UploadFilePayload, callback: Callback) =>
  //   axios.put(body.url, body.fileData, {
  //     headers: {
  //       'Content-Type': getFileType(body.fileData),
  //     },
  //     onUploadProgress: (progress) => {
  //       const { loaded, total } = progress;
  //       const percentageProgress = Math.floor((loaded / total) * 100);
  //       callback({ id: body.id, progress: percentageProgress });
  //     },
  //   });

  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  //
  // Notice we're not returning back the `api` created in step 1. That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    getRoot,
    // ====================== Auth ======================
    // getPermission,
    confirmSignIn,
    signIn,
    signUp,
    resendSignUp,
    confirmSignUp,
    signOut,
    forgotPassword,
    submitForgotPassword,
    changePassword,

    completeNewPassword,

    // ====================== File ======================
    // getPresignedUserServiceUrl,
    // uploadFile,
    // uploadFileWithProgress,
    getDecodeUserServiceUrl,

    // ====================== Content ======================
    getContents,

    // ====================== Users ======================
    getUserId,

    // ====================== Profile ======================
    getMyProfile,
    // updateMyProfile,
    updateUserAvatar,
    updateMyProfile,
    updateMailingAddress,
    updateDirectDeposit,
    confirmPassword,
  };
};

export type Apis = ReturnType<typeof create>;

export default {
  create,
};
