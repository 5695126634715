import { FieldInputProps } from 'formik';
import React from 'react';
import { Grid, Input } from 'src/components/common';
import { SignUpFormKey } from '../../helpers';
import { Callback } from 'src/redux/types';

const MailingAddressSection: React.FC<Props> = ({
  getFieldProps,
  getErrorMessage,
  handleChangeState,
}) => {
  return (
    <>
      <Grid.Item>
        <Input
          label={`Mailing Address, if different`}
          placeholder={`Enter your Mailing Address`}
          errorMessage={getErrorMessage(SignUpFormKey.MAILING_ADDRESS)}
          {...getFieldProps(SignUpFormKey.MAILING_ADDRESS)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Mailing City`}
          placeholder={`Enter your Mailing City`}
          errorMessage={getErrorMessage(SignUpFormKey.MAILING_CITY)}
          {...getFieldProps(SignUpFormKey.MAILING_CITY)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Mailing State`}
          placeholder={`Enter your Mailing State`}
          errorMessage={getErrorMessage(SignUpFormKey.MAILING_STATE)}
          {...getFieldProps(SignUpFormKey.MAILING_STATE)}
          onChange={handleChangeState}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Mailing Zip Code`}
          errorMessage={getErrorMessage(SignUpFormKey.MAILING_ZIP_CODE)}
          placeholder={`Enter your Mailing Zip Code`}
          {...getFieldProps(SignUpFormKey.MAILING_ZIP_CODE)}
        />
      </Grid.Item>
    </>
  );
};

type Props = {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  getErrorMessage: (fieldName: SignUpFormKey) => string;
  handleChangeState: Callback;
};

export default MailingAddressSection;
