import { FieldInputProps } from 'formik';
import React from 'react';
import { Checkbox, Grid, Text, View } from 'src/components/common';
import { SignUpFormKey, SignupFormValues } from '../helpers';

const ThirdPartyConfirm: React.FC<Props> = ({ getFieldProps, values }) => {
  return (
    <Grid.Item variant="is-full">
      <View align="center" isRowWrap>
        <Checkbox.Item
          {...getFieldProps(SignUpFormKey.IS_THIRD_PARTY_ADMIN)}
          checked={values?.[SignUpFormKey.IS_THIRD_PARTY_ADMIN]}
        />
        <Text>Are you a Third-Party Administrator?</Text>
      </View>
    </Grid.Item>
  );
};

type Props = {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  values: SignupFormValues;
};

export default ThirdPartyConfirm;
