import React from 'react';
import { TenantService } from 'src/services';
import EmployerForgotPassword from '../employer/ForgotPassword';
import { History, Location } from 'history';
import CustomErrorBoundary from 'src/components/ErrorBoundary/CustomErrorBoundary';

const ForgotPassword: React.FC<Props> = (props) => {
  if (TenantService.isEmployer())
    return (
      <CustomErrorBoundary showErrorMessage>
        <EmployerForgotPassword {...props} />
      </CustomErrorBoundary>
    );
  return null;
};

type Props = { history: History; location: Location<string> };

export default ForgotPassword;
