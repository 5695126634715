import { FieldInputProps } from 'formik';
import React from 'react';
import { SignUpFormKey } from '../../helpers';
import SectionText from '../../SectionText';
import AddressSection from '../AddressSection';
import MailingAddressSection from '../MailingAddressSection';
import { Callback } from 'src/redux/types';

const ClaimantAddress: React.FC<Props> = ({
  getFieldProps,
  getErrorMessage,
  handleChangeState,
}) => {
  return (
    <>
      <SectionText section="Claimant Address" />
      <AddressSection
        getFieldProps={getFieldProps}
        getErrorMessage={getErrorMessage}
        handleChangeState={handleChangeState}
      />
      <MailingAddressSection
        getFieldProps={getFieldProps}
        getErrorMessage={getErrorMessage}
        handleChangeState={handleChangeState}
      />
    </>
  );
};

type Props = {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  getErrorMessage: (fieldName: SignUpFormKey) => string;
  handleChangeState: Callback;
};

export default ClaimantAddress;
