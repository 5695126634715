import { FieldInputProps } from 'formik';
import React from 'react';
import {
  Grid,
  Input,
  InputPassword,
  PasswordSharingMessage,
  ValidatePassword,
} from 'src/components/common';
import { SignUpFormKey, SignupFormValues, USER_TYPE } from '../helpers';
import './styles.scss';

const EmailSection: React.FC<Props> = ({
  getFieldProps,
  values,
  getErrorMessage,
  emailLabel = 'your Work Email Address',
}) => {
  const emailFieldName =
    values?.userType === USER_TYPE.DEPENDENT || values?.userType === USER_TYPE.SPOUSE
      ? SignUpFormKey.DS_EMAIL_ADDRESS
      : SignUpFormKey.EMAIL;

  return (
    <>
      <Grid.Item variant="is-full">
        <Input
          label={emailLabel}
          required
          placeholder={`Enter ${emailLabel}`}
          errorMessage={getErrorMessage(emailFieldName)}
          {...getFieldProps(emailFieldName)}
          containerClassName="cmp-email-container"
        />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <InputPassword
          label="Password"
          required
          placeholder="Enter your Password"
          errorMessage={getErrorMessage(SignUpFormKey.PASSWORD)}
          {...getFieldProps(SignUpFormKey.PASSWORD)}
        />
        <ValidatePassword password={values?.password} />
        <PasswordSharingMessage />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <InputPassword
          label="Confirm Password"
          required
          placeholder="Enter your Confirm Password"
          errorMessage={getErrorMessage(SignUpFormKey.CONFIRM_PASSWORD)}
          {...getFieldProps(SignUpFormKey.CONFIRM_PASSWORD)}
        />
      </Grid.Item>
    </>
  );
};

type Props = {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  values: SignupFormValues;
  getErrorMessage: (fieldName: SignUpFormKey) => string;
  emailLabel?: string;
  emailPlaceholder?: string;
};

export default EmailSection;
