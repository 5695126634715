import { PATHS } from 'src/appConfig/paths';
import { FallbackProps } from '../CustomErrorBoundary';
import { Navigator } from 'src/services';
import { Button, View } from 'src/components/common';
import { Box, Container, Typography } from '@material-ui/core';

const DefaultErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
  showErrorMessage = false,
}) => {
  const handleBackToHome = () => {
    resetErrorBoundary();
    Navigator.navigate(PATHS.signIn);
  };

  const handleTryAgain = () => {
    resetErrorBoundary();
    window.location.reload();
  };

  return (
    <Container>
      <Box minHeight="70vh" pt={8} textAlign="center">
        <Typography variant="h2">Unfortunately, something went wrong.</Typography>
        <Typography className="mt-16">
          Please refresh your browser. If the error continues, please contact our support team.
        </Typography>

        {showErrorMessage && (
          <Box mt={4}>
            <pre>{error?.message}</pre>
          </Box>
        )}

        <View align="center" justify="center">
          <Button onClick={handleBackToHome}>Back to Login</Button>
          <Button className="mt-16" onClick={handleTryAgain} variant="outline">
            Try again
          </Button>
        </View>
      </Box>
    </Container>
  );
};

export default DefaultErrorFallback;
