/* eslint-disable react-hooks/exhaustive-deps */
import { History } from 'history';
import React from 'react';
import { IoDocumentText } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import { Text, View } from 'src/components/common';
import { UAMBody } from '../../common';
import '../../Signin/styles.scss';
import { workersCompensationOptions } from './helpers';
import CustomErrorBoundary from 'src/components/ErrorBoundary/CustomErrorBoundary';

const Home: React.FC<Props> = () => {
  return (
    <CustomErrorBoundary showErrorMessage>
      <UAMBody>
        <View isRowWrap align="center">
          <i>
            <IoDocumentText size={24} color={'#706e6b'} />
          </i>
          <a
            href="https://labor.hawaii.gov/dcd/forms/"
            target="_blank"
            rel="noopener noreferrer"
            className="has-text-primary cursor-pointer ml-8 text-is-16"
          >
            Forms
          </a>
        </View>
        <View className="mt-16">
          <Text size={18} className="fw-bold">
            WORKERS' COMPENSATION
          </Text>

          <View className="pl-16 mt-16">
            {workersCompensationOptions.map((item) => (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className="has-text-primary cursor-pointer mb-8"
              >
                Submit a <b>{item.id.toUpperCase()}</b> {item.label}
              </a>
            ))}
          </View>
        </View>
        <View>
          <NavLink
            className={'fw-medium text-is-16 has-text-primary text-align-center mt-48'}
            to={PATHS.signIn}
          >
            Back to Log In
          </NavLink>
        </View>
      </UAMBody>
    </CustomErrorBoundary>
  );
};

type Props = { history: History };

export default Home;
