import { FieldInputProps } from 'formik';
import React from 'react';
import { Callback } from 'src/redux/types';
import { SignUpFormKey, SignupFormValues } from '../helpers';
import PersonalInformation from '../PersonalInformation';
import ClaimantInformation from './ClaimantInformation';

const LegalGuardianQuestion: React.FC<Props> = ({
  getFieldProps,
  values,
  getErrorMessage,
  setFieldValue,
  setFieldTouched,
  handleChangeState,
}) => {
  return (
    <>
      <PersonalInformation
        getFieldProps={getFieldProps}
        getErrorMessage={getErrorMessage}
        values={values}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <ClaimantInformation
        getFieldProps={getFieldProps}
        getErrorMessage={getErrorMessage}
        values={values}
        setFieldValue={setFieldValue}
        handleChangeState={handleChangeState}
        setFieldTouched={setFieldTouched}
      />
    </>
  );
};

type Props = {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  values: SignupFormValues;
  getErrorMessage: (fieldName: SignUpFormKey) => string;
  setFieldTouched: Callback;
  setFieldValue: Callback;
  handleChangeState: Callback;
};

export default LegalGuardianQuestion;
