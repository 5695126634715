enum AdjusterType {
  SelfInsured = 'Self-Insured',
  AdjustingCompany = 'Adjusting Company',
}

export const adjusterTypeOptions = [
  {
    label: 'Self-Insured',
    value: AdjusterType.SelfInsured,
  },
  {
    label: 'Adjusting Company',
    value: AdjusterType.AdjustingCompany,
  },
];
