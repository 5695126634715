import React from 'react';
import { Grid, Text } from 'src/components/common';

const SectionText: React.FC<Props> = ({ section }) => (
  <Grid.Item variant="is-full">
    <Text size={16} className={'fw-medium text-color-grey-900'}>
      {section}
    </Text>
  </Grid.Item>
);
type Props = {
  section: string;
};

export default SectionText;
