import { Yup } from 'src/services';
import { SchemaOf } from 'yup';

export enum PORTAL {
  ADJUSTER = 'ADJUSTER',
  CLAIMANT = 'CLAIMANT',
  EMPLOYER = 'EMPLOYER',
  HEALTH_CARE_CONTRACTOR = 'HEALTH_CARE_CONTRACTOR',
  INSURANCE_CARRIER = 'INSURANCE_CARRIER',
  PHYSICIAN_IND = 'PHYSICIAN_IND',
  PHYSICIAN_ORG = 'PHYSICIAN_ORG',
  REPRESENTATIVE_IND = 'REPRESENTATIVE_IND',
  AGENT_ORG = 'AGENT_ORG',
  ATTORNEY_EMPLOYER = 'ATTORNEY_EMPLOYER',
  ATTORNEY_CLAIMANT = 'ATTORNEY_CLAIMANT',
  VOCATIONAL_REHABILITATION_COUNSELOR = 'VOCATIONAL_REHABILITATION_COUNSELOR',
  BILL_DISPUTE = 'BILL_DISPUTE',
  LEGAL_GUARDIAN = 'LEGAL_GUARDIAN',
}

export enum USER_CLASSIFICATION {
  CLAIMANT = 'Claimant',
  EMPLOYER = 'Employer',
  INSURANCE = 'Insurance',
  HEALTH_CARE_PROVIDER = 'Health Care Provider',
  REPRESENTATIVE = 'Representative',
  LEGAL = 'Legal',
  GOVERNMENT_AGENCY = 'Government Agency',
  CONTRACTOR = 'Contractor',
  EMPLOYEE = 'Employee',
}

export enum USER_TYPE {
  EMPLOYEE = 'Employee',
  DEPENDENT = 'Dependent',
  SPOUSE = 'Spouse',
  EMPLOYER = 'Employer',
  INVESTIGATION = 'Investigation',
  INSURANCE = 'Insurance',
  THIRD_PARTY_ADMIN = '3rd Party Administrator',
  CARRIER = 'Carrier',
  ADJUSTER = 'Adjuster',
  PHYSICIAN = 'Physician',
  OTHER = 'Other',
  ATTENDANT = 'Attendant',
  BILL_REVIEW = 'Bill Dispute',
  VOCATIONAL_REHABILITATION_COUNSELOR = 'Vocational Rehabilitation Counselor',
  SELF_INSURED = 'Self-Insured',
  LEGAL_GUARDIAN = 'Legal Guardian',
  ATTORNEY_FOR_CLAIMANT = 'Attorney for Claimant',
  ATTORNEY_FOR_EMPLOYER = 'Attorney for Employer',
  POWER_OF_ATTORNEY = 'Power of Attorney',
  ATTORNEY_SPECIAL_COMPENSATION_FUND = 'Attorney (Special Compensation Fund)',
  ATTORNEY_GENERAL_OFFICE = 'Attorney (Attorney General Office)',
  CORPORATE_COUNCIL = 'Corporate Council',
  LEGAL_COUNSEL_FOR_CLAIMANT = 'Legal Counsel for Claimant',
  LEGAL_COUNSEL_FOR_EMPLOYER = 'Legal Counsel for Employer',
  MEDQUEST = 'MedQuest',
  MEDICARE = 'Medicare',
  CHILD_SUPPORT = 'Child Support',
  SOCIAL_SECURITY_OFFICE = 'Social Security Office',
  AUDIT = 'Audit',
  CONTRACTOR = 'Contractor',
}

export enum LEGAL_SERVICE_TYPE {
  POWER_OF_ATTORNEY = 'Power of Attorney',
  ATTORNEY_SPECIAL_COMPENSATION_FUND = 'Attorney (Special Compensation Fund)',
  ATTORNEY_GENERAL = 'Attorney General',
  ATTORNEY = 'Attorney',
  LEGAL_COUNSEL = 'Legal Counsel',
  LEGAL_GUARDIAN = 'Legal Guardian',
  VOCATIONAL_REHABILITATION_COUNSELOR = 'Vocational Rehabilitation Counselor',
  SPECIAL_COMPENSATION_FUND = 'Special Compensation Fund',
}

export enum ISLAND_OF_SERVICE {
  OAHU = 'Oahu',
  HAWAII_ISLAND = 'Hawaii Island',
  MAUI = 'Maui',
  KAUAI = 'Kauai',
  MOLOKAI = 'Molokai',
  LANAI = 'Lanai',
  NIIHAU = 'Niihau',
}

export enum GOVERNMENT_AGENCY_SERVICE {
  MEDQUEST = 'MedQuest',
  PUBLIC_HEALTH_BENEFITS = 'Public Health benefits',
  SOCIAL_SECURITY = 'Social Security',
  CHILD_SUPPORT = 'Child Support',
}

export enum PROGRAM_TYPE {
  WC = 'WC',
  TDI = 'TDI',
  PHC = 'PHC',
}

export enum ADMIN_SERVICE {
  WC = 'WC',
  TDI = 'TDI',
}

export const toArrayValue = (values: OptionType[]) => values?.map(({ value }) => value);

export interface OptionType {
  value: any;
  label: string;
  key?: string;
}

export const isContain = (parentArray: Array<any>, array: Array<any>) =>
  parentArray.some((element) => array.includes(element));

export const isParentArray = (parentArray: Array<any>, array: Array<any>) =>
  array.every((element) => parentArray?.includes(element));

export const legalServiceTypeOptions = [
  { value: LEGAL_SERVICE_TYPE.ATTORNEY, label: 'Attorney' },
  { value: LEGAL_SERVICE_TYPE.POWER_OF_ATTORNEY, label: 'Power of Attorney' },
  { value: LEGAL_SERVICE_TYPE.LEGAL_COUNSEL, label: 'Legal Counsel' },
  { value: LEGAL_SERVICE_TYPE.LEGAL_GUARDIAN, label: 'Legal Guardian' },
  {
    value: LEGAL_SERVICE_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR,
    label: 'Vocational Rehabilitation Counselor',
  },
  { value: LEGAL_SERVICE_TYPE.ATTORNEY_GENERAL, label: 'Attorney General' },
  { value: LEGAL_SERVICE_TYPE.SPECIAL_COMPENSATION_FUND, label: 'Special Compensation Fund' },
];

export const governmentAgencyServiceOptions = [
  { value: GOVERNMENT_AGENCY_SERVICE.MEDQUEST, label: 'MedQuest' },
  { value: GOVERNMENT_AGENCY_SERVICE.PUBLIC_HEALTH_BENEFITS, label: 'Public Health benefits' },
  { value: GOVERNMENT_AGENCY_SERVICE.SOCIAL_SECURITY, label: 'Social Security' },
  { value: GOVERNMENT_AGENCY_SERVICE.CHILD_SUPPORT, label: 'Child Support' },
];

export const programTypeOptions = [
  { value: PROGRAM_TYPE.WC, label: `Workers' Compensation` },
  { value: PROGRAM_TYPE.TDI, label: 'Temporary Disability Insurance' },
  { value: PROGRAM_TYPE.PHC, label: 'Prepaid Health Care' },
];

export const adminServiceOptions = [
  { value: PROGRAM_TYPE.WC, label: 'WC' },
  { value: PROGRAM_TYPE.TDI, label: 'TDI' },
];

export const islandServiceOptions = [
  { value: ISLAND_OF_SERVICE.OAHU, label: 'Oahu' },
  { value: ISLAND_OF_SERVICE.HAWAII_ISLAND, label: 'Hawaii Island' },
  { value: ISLAND_OF_SERVICE.MAUI, label: 'Maui' },
  { value: ISLAND_OF_SERVICE.KAUAI, label: 'Kauai' },
  { value: ISLAND_OF_SERVICE.MOLOKAI, label: 'Molokai' },
  { value: ISLAND_OF_SERVICE.LANAI, label: 'Lanai' },
  { value: ISLAND_OF_SERVICE.NIIHAU, label: 'Niihau' },
];

export enum SERVICES_TYPE {
  PHYSICIAN_ASSISTANT = 'Physician assistant',
  MEDICINE = 'Medicine',
  APRN = 'APRN',
  DENTIST = 'Dentist',
  CHIROPRACTOR = 'Chiropractor',
  NATURO_PATH = 'Naturo Path',
  PSYCHOLOGIST = 'Psychologist',
  OPTOMETRIST = 'Optometrist',
  PODIATRIST = 'Podiatrist',
  SPECIALIST = 'Specialist',
}

export enum OTHER_SERVICE_TYPE {
  OCCUPATIONAL_THERAPIST = 'Occupational Therapist',
  PHYSICAL_THERAPIST = 'Physical Therapist',
  MASSAGE = 'Massage',
  ACCUPUNCTURIST = 'Accupuncturist',
}

export const otherServiceTypeOptions = [
  { value: OTHER_SERVICE_TYPE.OCCUPATIONAL_THERAPIST, label: 'Occupational Therapist' },
  { value: OTHER_SERVICE_TYPE.PHYSICAL_THERAPIST, label: 'Physical Therapist' },
  { value: OTHER_SERVICE_TYPE.MASSAGE, label: ' Massage' },
  { value: OTHER_SERVICE_TYPE.ACCUPUNCTURIST, label: 'Accupuncturist' },
];

export const serviceTypeOptions = [
  { value: SERVICES_TYPE.PHYSICIAN_ASSISTANT, label: ' Physician assistant' },
  { value: SERVICES_TYPE.MEDICINE, label: ' Medicine' },
  { value: SERVICES_TYPE.APRN, label: ' APRN' },
  { value: SERVICES_TYPE.DENTIST, label: 'Dentist' },
  { value: SERVICES_TYPE.CHIROPRACTOR, label: 'Chiropractor' },
  { value: SERVICES_TYPE.NATURO_PATH, label: 'Naturo Path' },
  { value: SERVICES_TYPE.OPTOMETRIST, label: 'Optometrist' },
  { value: SERVICES_TYPE.PSYCHOLOGIST, label: 'Psychologist' },
  { value: SERVICES_TYPE.PODIATRIST, label: ' Podiatrist' },
  { value: SERVICES_TYPE.SPECIALIST, label: 'Specialist' },
];

export const OtherServiceTypeOptions = [
  { value: SERVICES_TYPE.PHYSICIAN_ASSISTANT, label: ' Physician assistant' },
  { value: SERVICES_TYPE.MEDICINE, label: ' Medicine' },
  { value: SERVICES_TYPE.APRN, label: ' APRN' },
  { value: SERVICES_TYPE.DENTIST, label: 'Dentist' },
  { value: SERVICES_TYPE.CHIROPRACTOR, label: 'Chiropractor' },
  { value: SERVICES_TYPE.NATURO_PATH, label: 'Naturo Path' },
  { value: SERVICES_TYPE.OPTOMETRIST, label: 'Optometrist' },
  { value: SERVICES_TYPE.PSYCHOLOGIST, label: 'Psychologist' },
  { value: SERVICES_TYPE.PODIATRIST, label: ' Podiatrist' },
  { value: SERVICES_TYPE.SPECIALIST, label: 'Specialist' },
];

export enum IDENTIFICATION_TYPE {
  SOCIAL_SECURITY_NUMBER = 'Social Security Number',
  PASSPORT = 'Passport',
}

export enum ORGANIZATION_TYPE {
  CORPORATION = 'Corporation',
  PARTNERSHIP = 'Partnership',
  SOLE_PROPRIETOR = 'Sole Proprietor',
  OTHER = 'Other',
}

export const organizationTypeOptions = [
  {
    value: ORGANIZATION_TYPE.CORPORATION,
    label: 'Corporation',
  },
  {
    value: ORGANIZATION_TYPE.PARTNERSHIP,
    label: 'Partnership',
  },
  {
    value: ORGANIZATION_TYPE.SOLE_PROPRIETOR,
    label: 'Sole Proprietor',
  },
  {
    value: ORGANIZATION_TYPE.OTHER,
    label: 'Other',
  },
];

export const identificationTypeOptions = [
  {
    value: IDENTIFICATION_TYPE.SOCIAL_SECURITY_NUMBER,
    label: 'Social Security Number',
  },
  {
    value: IDENTIFICATION_TYPE.PASSPORT,
    label: 'Passport',
  },
];

export const userClassificationOptions = [
  {
    value: USER_CLASSIFICATION.CLAIMANT,
    label: 'Claimant',
    notInProgramTypeCondition: [PROGRAM_TYPE.PHC],
  },
  {
    value: USER_CLASSIFICATION.EMPLOYER,
    label: 'Employer',
    notInProgramTypeCondition: [],
  },
  {
    value: USER_CLASSIFICATION.INSURANCE,
    label: 'Insurance',
    notInProgramTypeCondition: [],
  },
  {
    value: USER_CLASSIFICATION.EMPLOYEE,
    label: 'Employee',
    notInProgramTypeCondition: [PROGRAM_TYPE.WC, PROGRAM_TYPE.TDI],
  },

  {
    value: USER_CLASSIFICATION.HEALTH_CARE_PROVIDER,
    label: 'Health Care Provider',
    notInProgramTypeCondition: [PROGRAM_TYPE.PHC],
    programTypeCondition: [PROGRAM_TYPE.WC, PROGRAM_TYPE.TDI],
  },
  {
    value: USER_CLASSIFICATION.REPRESENTATIVE,
    label: 'Representative',
    notInProgramTypeCondition: [PROGRAM_TYPE.PHC],
  },
  {
    value: USER_CLASSIFICATION.LEGAL,
    label: 'Legal',
    notInProgramTypeCondition: [PROGRAM_TYPE.PHC],
  },
  {
    value: USER_CLASSIFICATION.GOVERNMENT_AGENCY,
    label: 'Government Agency',
    notInProgramTypeCondition: [PROGRAM_TYPE.PHC, PROGRAM_TYPE.TDI, PROGRAM_TYPE.WC],
  },
  {
    value: USER_CLASSIFICATION.CONTRACTOR,
    label: 'Contractor',
    notInProgramTypeCondition: [PROGRAM_TYPE.TDI, PROGRAM_TYPE.WC],
  },
];

export const userTypeOptions = [
  {
    value: USER_TYPE.EMPLOYEE,
    label: 'Employee',
    userClassificationCondition: [USER_CLASSIFICATION.CLAIMANT, USER_CLASSIFICATION.EMPLOYEE],
    portal: PORTAL.CLAIMANT,
  },
  {
    value: USER_TYPE.DEPENDENT,
    label: 'Dependent',
    userClassificationCondition: [USER_CLASSIFICATION.CLAIMANT],
    portal: PORTAL.CLAIMANT,
  },
  {
    value: USER_TYPE.SPOUSE,
    label: 'Spouse',
    userClassificationCondition: [USER_CLASSIFICATION.CLAIMANT],
    portal: PORTAL.CLAIMANT,
  },
  {
    value: USER_TYPE.EMPLOYER,
    label: 'Employer',
    userClassificationCondition: [USER_CLASSIFICATION.EMPLOYER],
    portal: PORTAL.EMPLOYER,
  },
  {
    value: USER_TYPE.AUDIT,
    label: 'Audit',
    userClassificationCondition: [],
    portal: PORTAL.EMPLOYER,
  },
  {
    value: USER_TYPE.INVESTIGATION,
    label: 'Investigation',
    userClassificationCondition: [],
    portal: PORTAL.EMPLOYER,
  },
  {
    value: USER_TYPE.INSURANCE,
    label: 'Insurance',
    userClassificationCondition: [],
    portal: PORTAL.EMPLOYER,
  },
  {
    value: USER_TYPE.CARRIER,
    label: 'Carrier',
    userClassificationCondition: [USER_CLASSIFICATION.INSURANCE],
    programTypeCondition: [PROGRAM_TYPE.PHC],
    portal: PORTAL.INSURANCE_CARRIER,
  },
  {
    value: USER_TYPE.THIRD_PARTY_ADMIN,
    label: '3rd Party Administrator',
    userClassificationCondition: [],
    portal: PORTAL.ADJUSTER,
  },
  {
    value: USER_TYPE.SELF_INSURED,
    label: 'Self-Insured',
    userClassificationCondition: [USER_CLASSIFICATION.INSURANCE],
    portal: PORTAL.INSURANCE_CARRIER,
  },
  {
    value: USER_TYPE.ADJUSTER,
    label: 'Adjuster',
    userClassificationCondition: [USER_CLASSIFICATION.INSURANCE],
    programTypeCondition: [PROGRAM_TYPE.PHC],
    portal: PORTAL.ADJUSTER,
  },
  {
    value: USER_TYPE.PHYSICIAN,
    label: 'Physician',
    userClassificationCondition: [USER_CLASSIFICATION.HEALTH_CARE_PROVIDER],
    programTypeCondition: [PROGRAM_TYPE.TDI],
    portal: PORTAL.PHYSICIAN_IND,
  },
  {
    value: USER_TYPE.OTHER,
    label: 'Other',
    userClassificationCondition: [],
    portal: PORTAL.PHYSICIAN_IND,
  },
  {
    value: USER_TYPE.ATTENDANT,
    label: 'Attendant',
    userClassificationCondition: [],
    portal: PORTAL.REPRESENTATIVE_IND,
  },
  {
    value: USER_TYPE.BILL_REVIEW,
    label: 'Bill Dispute',
    userClassificationCondition: [USER_CLASSIFICATION.REPRESENTATIVE],
    programTypeCondition: [PROGRAM_TYPE.TDI],
    portal: PORTAL.BILL_DISPUTE,
  },
  {
    value: USER_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR,
    label: 'Vocational Rehabilitation Counselor',
    userClassificationCondition: [USER_CLASSIFICATION.REPRESENTATIVE],
    programTypeCondition: [PROGRAM_TYPE.TDI],
    portal: PORTAL.VOCATIONAL_REHABILITATION_COUNSELOR,
  },
  {
    value: USER_TYPE.POWER_OF_ATTORNEY,
    label: 'Power of Attorney',
    userClassificationCondition: [],
    portal: PORTAL.REPRESENTATIVE_IND,
  },
  {
    value: USER_TYPE.LEGAL_GUARDIAN,
    label: 'Legal Guardian',
    userClassificationCondition: [USER_CLASSIFICATION.REPRESENTATIVE],
    portal: PORTAL.LEGAL_GUARDIAN,
  },
  {
    value: USER_TYPE.ATTORNEY_FOR_CLAIMANT,
    label: 'Attorney for Claimant',
    userClassificationCondition: [USER_CLASSIFICATION.LEGAL],
    portal: PORTAL.ATTORNEY_CLAIMANT,
  },
  {
    value: USER_TYPE.ATTORNEY_FOR_EMPLOYER,
    label: 'Attorney for Employer',
    userClassificationCondition: [USER_CLASSIFICATION.LEGAL],
    portal: PORTAL.ATTORNEY_EMPLOYER,
  },
  {
    value: USER_TYPE.ATTORNEY_SPECIAL_COMPENSATION_FUND,
    label: 'Attorney (Special Compensation Fund)',
    userClassificationCondition: [],
    portal: PORTAL.INSURANCE_CARRIER,
  },
  {
    value: USER_TYPE.ATTORNEY_GENERAL_OFFICE,
    label: 'Attorney (Attorney General Office)',
    userClassificationCondition: [USER_CLASSIFICATION.LEGAL],
    portal: PORTAL.CLAIMANT,
  },
  {
    value: USER_TYPE.CORPORATE_COUNCIL,
    label: 'Corporate Council',
    userClassificationCondition: [],
    portal: PORTAL.REPRESENTATIVE_IND,
  },
  {
    value: USER_TYPE.LEGAL_COUNSEL_FOR_CLAIMANT,
    label: 'Legal Counsel for Claimant',
    userClassificationCondition: [],
    portal: PORTAL.CLAIMANT,
  },
  {
    value: USER_TYPE.LEGAL_COUNSEL_FOR_EMPLOYER,
    label: 'Legal Counsel for Employer',
    userClassificationCondition: [],
    portal: PORTAL.EMPLOYER,
  },
  {
    value: USER_TYPE.MEDQUEST,
    label: 'MedQuest',
    userClassificationCondition: [USER_CLASSIFICATION.GOVERNMENT_AGENCY],
    portal: PORTAL.REPRESENTATIVE_IND,
  },
  {
    value: USER_TYPE.MEDICARE,
    label: 'Medicare',
    userClassificationCondition: [USER_CLASSIFICATION.GOVERNMENT_AGENCY],
    portal: PORTAL.REPRESENTATIVE_IND,
  },
  {
    value: USER_TYPE.CHILD_SUPPORT,
    label: 'Child Support',
    userClassificationCondition: [USER_CLASSIFICATION.GOVERNMENT_AGENCY],
    portal: PORTAL.REPRESENTATIVE_IND,
  },
  {
    value: USER_TYPE.SOCIAL_SECURITY_OFFICE,
    label: 'Social Security Office',
    userClassificationCondition: [USER_CLASSIFICATION.GOVERNMENT_AGENCY],
    portal: PORTAL.REPRESENTATIVE_IND,
  },
  {
    value: USER_TYPE.CONTRACTOR,
    label: 'Health Care Contractor',
    userClassificationCondition: [USER_CLASSIFICATION.CONTRACTOR],
    portal: PORTAL.HEALTH_CARE_CONTRACTOR,
  },
];

export const portalOptions = [
  { value: PORTAL.ADJUSTER, label: 'Adjuster' },
  { value: PORTAL.CLAIMANT, label: 'Claimant' },
  { value: PORTAL.EMPLOYER, label: 'Employer' },
  { value: PORTAL.HEALTH_CARE_CONTRACTOR, label: 'Health Care Contractor' },
  { value: PORTAL.INSURANCE_CARRIER, label: 'Insurance Carrier' },
  { value: PORTAL.PHYSICIAN_IND, label: 'Physician (Ind)' },
  { value: PORTAL.PHYSICIAN_ORG, label: 'Physician (Org)' },
  { value: PORTAL.REPRESENTATIVE_IND, label: 'Representative (Ind)' },
  { value: PORTAL.AGENT_ORG, label: 'Agent (Org)' },
];

export enum SignUpFormKey {
  PROGRAM_TYPE = 'programType',
  USER_CLASSIFICATION = 'userClassification',
  USER_TYPE = 'userType',
  PORTAL = 'portal',
  EMAIL = 'email',
  PASSWORD = 'password', // pragma: allowlist secret
  CONFIRM_PASSWORD = 'confirmPassword', // pragma: allowlist secret
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DOB = 'dob',
  ADMIN_SERVICES = 'adminServices',
  ADMIN_SERVICES_NAME = 'adminServicesName',
  ADJUSTER_ID = 'adjusterId',
  HEALTH_CARE_CONTRACTOR_NAME = 'healthCareContractorName',
  INSURANCE_CARRIER_NAME = 'insuranceCarrierName',
  PHYSICIAN_NAME = 'physicianName',
  ADDRESS = 'address',
  STATE = 'state',
  CITY = 'city',
  ZIP_CODE = 'zipCode',
  MIDDLE_INITIAL = 'middleInitial',
  PHONE_NUMBER = 'phoneNumber',
  RELATION_TO_CLAIMANT = 'relationToClaimant',
  MIDDLE_NAME = 'middleName',
  IDENTIFICATION_TYPE = 'identificationType',
  IDENTIFICATION_NUMBER = 'identificationNumber',
  MAILING_ADDRESS = 'mailingAddress',
  MAILING_STREET = 'mailingStreet',
  MAILING_STATE = 'mailingState',
  MAILING_CITY = 'mailingCity',
  MAILING_ZIP_CODE = 'mailingZipCode',
  PARENT_COMPANY_NAME = 'parentCompanyName',
  NATURE_OF_BUSINESS = 'natureOfBusiness',
  ORGANIZATION_TYPE = 'organizationType',
  FEDERAL_ID_NUMBER = 'federalIDNumber',
  CONTACT_NUMBER = 'contactNumber',
  ORGANIZATION_CONTACT_NUMBER = 'organizationContactNumber',
  ADJUSTING_SERVICES = 'adjustingServices',
  ADJUSTING_SERVICES_NAME = 'adjustingServicesName',
  SERVICE_TYPE = 'serviceType',
  ISLAND_OF_SERVICE = 'islandOfService',
  REGISTRATION_EXPIRATION_DATE = 'registrationExpirationDate',
  HEALTH_CARE_CONTRACTOR = 'healthCareContractor',
  AGENCY_NAME = 'agencyName',
  EMPLOYER_ADDRESS = 'employerAddress',
  EMPLOYER_CITY = 'employerCity',
  EMPLOYER_ZIP_CODE = 'employerZipCode',
  EOF_MAILING_ADDRESS = 'eofMailingAddress',
  EOF_MAILING_STREET = 'eofMailingStreet',
  EOF_MAILING_CITY = 'eofMailingCity',
  EOF_MAILING_STATE = 'eofMailingState',
  EOF_MAILING_ZIP_CODE = 'eofMailingZipCode',
  EOF_ADDRESS = 'eofAddress',
  EOF_STREET = 'eofStreet',
  EOF_CITY = 'eofCity',
  EOF_STATE = 'eofState',
  EOF_ZIP_CODE = 'eofZipCode',
  EOF_PHONE_NUMBER = 'eofPhoneNumber',
  EOF_NAME = 'eofName',

  DS_FIRST_NAME = 'dsFirstName',
  DS_LAST_NAME = 'dsLastName',
  DS_MIDDLE_NAME = 'dsMiddleName',
  DS_BIRTHDAY = 'dsBirthDate',
  DS_PHONE_NUMBER = 'dsPhoneNumber',
  DS_EMAIL_ADDRESS = 'dsEmailAddress',
  DS_ID_NUMBER = 'dsIdNumber',
  CLAIMANT_EMAIL = 'claimantEmail',
  IS_AGREEMENT = 'isAgree',
  CLAIMANT_ID_TYPE = 'claimantIdType',
  CLAIMANT_ID_NUMBER = 'claimantIdNumber',

  IS_THIRD_PARTY_ADMIN = 'eofIsThirdPartyAdmin',
  ADJUSTER_TYPE = 'eofSelfInsuredOrAdjustingCompany',
}

export type SignupFormValues = {
  programType: OptionType[];
  userClassification: string;
  userType: string;
  portal: PORTAL;
  email: string;
  password: string;
  confirmPassword: string;

  lastName: string;
  firstName: string;
  dob?: string | Date;

  adjustingServicesName?: string; //
  adminServices: string;
  adminServicesName: string;
  adjusterId?: string; //adjuster
  eofName?: string; //eof
  eofZipCode?: string; //eof
  eofState?: string; //eof
  eofCity?: string; //eof
  eofAddress?: string; //employer
  healthCareContractorName?: string; //healthcare contractor
  insuranceCarrierName?: string; //insurance carrier
  physicianName?: string; //physician org

  address?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  phoneNumber: string;
  relationToClaimant?: string;
  identificationType: string;
  identificationNumber: string;
  middleName?: string;
  parentCompanyName?: string;
  natureOfBusiness?: string;
  organizationType?: string;
  contactNumber: string;
  organizationContactNumber: string;
  adjustingServices?: string;
  federalIDNumber: string;
  serviceType?: string;
  islandOfService?: OptionType[];
  registrationExpirationDate?: string | Date;
  agencyName?: string;
  healthCareContractor?: string;
  eofStreet?: string;
  eofPhoneNumber?: string;
  eofMailingAddress?: string;
  eofMailingStreet?: string;
  eofMailingZipCode?: string; //eof
  eofMailingState?: string; //eof
  eofMailingCity?: string; //eof

  dsFirstName?: string;
  dsLastName?: string;
  dsMiddleName?: string;
  dsBirthDate?: string | Date;
  dsPhoneNumber?: string;
  dsEmailAddress?: string;
  dsIdNumber?: string;
  isAgree: boolean;

  eofSelfInsuredOrAdjustingCompany?: string;
  eofIsThirdPartyAdmin?: boolean;

  recaptchaToken?: string;
};

export const initialSignupFormValues = {
  programType: [],
  portal: PORTAL.ADJUSTER,
  userType: '',
  email: '',
  password: '',
  confirmPassword: '',
  lastName: '',
  firstName: '',

  adjustingServicesName: '', //adjuster
  adjusterId: '', //adjuster
  healthCareContractorName: '', //healthcare contractor

  insuranceCarrierName: '', //insurance carrier
  physicianName: '', //physician org

  address: '',
  state: '',
  city: '',
  zipCode: '',
  middleInitial: '',
  phoneNumber: '',
  relationToClaimant: '',
  identificationType: '',
  identificationNumber: '',
  mailingAddress: '',
  mailingState: '',
  mailingCity: '',
  mailingZipCode: '',
  employerAddress: '',
  employerState: '',
  employerCity: '',
  employerZipCode: '',
  middleName: '',
  parentCompanyName: '',
  natureOfBusiness: '',
  organizationType: '',
  contactNumber: '',
  organizationContactNumber: '',
  federalIDNumber: '',
  adjustingServices: '',
  serviceType: '',
  islandOfService: [],
  userClassification: '',
  agencyName: '',
  healthCareContractor: '',
  eofStreet: '',
  eofMailingAddress: '',
  eofName: '',
  eofZipCode: '',
  eofState: '',
  eofCity: '',
  eofAddress: '',
  eofPhoneNumber: '',
  dob: null,
  registrationExpirationDate: null,
  adminServices: '',
  adminServicesName: '',

  dsFirstName: '',
  dsLastName: '',
  dsMiddleName: '',
  dsBirthDate: null,
  dsPhoneNumber: '',
  dsEmailAddress: '',
  dsIdNumber: '',
  claimantEmail: '',
  isAgree: false,

  claimantIdType: '',
  claimantIdNumber: '',
  eofSelfInsuredOrAdjustingCompany: '',
  eofIsThirdPartyAdmin: false,
};

const wrapIsDependentOrSpouseSchema = (schema: SchemaOf<any>) =>
  Yup.mixed().when(SignUpFormKey.USER_TYPE, {
    is: (userType: USER_TYPE) => userType === USER_TYPE.SPOUSE || userType === USER_TYPE.DEPENDENT,
    then: schema,
  });

const wrapIsDependentOrSpouseOrLegalGuardianSchema = (schema: SchemaOf<any>) =>
  Yup.mixed().when(SignUpFormKey.USER_TYPE, {
    is: (userType: USER_TYPE) =>
      userType === USER_TYPE.SPOUSE ||
      userType === USER_TYPE.DEPENDENT ||
      userType === USER_TYPE.LEGAL_GUARDIAN,
    then: schema,
  });

const wrapIsClaimantSchema = (schema: SchemaOf<any>) =>
  Yup.mixed().when(SignUpFormKey.USER_TYPE, {
    is: (userType: USER_TYPE) =>
      userType === USER_TYPE.SPOUSE ||
      userType === USER_TYPE.DEPENDENT ||
      userType === USER_TYPE.EMPLOYEE,
    then: schema,
  });

const wrapIsClaimantOrLegalGuardianSchema = (schema: SchemaOf<any>) =>
  Yup.mixed().when(SignUpFormKey.USER_TYPE, {
    is: (userType: USER_TYPE) =>
      userType === USER_TYPE.SPOUSE ||
      userType === USER_TYPE.DEPENDENT ||
      userType === USER_TYPE.EMPLOYEE ||
      userType === USER_TYPE.LEGAL_GUARDIAN,
    then: schema,
  });

const wrapIsSelfInsuredOrEmployer = (schema: SchemaOf<any>) =>
  Yup.mixed().when([SignUpFormKey.USER_TYPE, SignUpFormKey.USER_CLASSIFICATION], {
    is: (userType: USER_TYPE, userClassification: USER_CLASSIFICATION) =>
      userType === USER_TYPE.SELF_INSURED || userClassification === USER_CLASSIFICATION.EMPLOYER,
    then: schema,
  });

const wrapEOFCity = (schema: SchemaOf<any>) =>
  Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_TYPE, SignUpFormKey.USER_CLASSIFICATION], {
      is: (userType: USER_TYPE, userClassification: USER_CLASSIFICATION) =>
        userType === USER_TYPE.EMPLOYEE ||
        userType === USER_TYPE.SPOUSE ||
        userType === USER_TYPE.DEPENDENT ||
        userClassification === USER_CLASSIFICATION.EMPLOYER ||
        userType === USER_TYPE.CARRIER ||
        userType === USER_TYPE.ADJUSTER ||
        userType === USER_TYPE.THIRD_PARTY_ADMIN ||
        userType === USER_TYPE.SELF_INSURED ||
        userType === USER_TYPE.CONTRACTOR ||
        userType === USER_TYPE.BILL_REVIEW ||
        userType === USER_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR ||
        userClassification === USER_CLASSIFICATION.LEGAL,
      then: schema,
    });

export const signupFormValidationSchema = Yup.object().shape({
  programType: Yup.array().min(1, 'Program Type is required.'),
  userClassification: Yup.string().required('User Classification is required'),
  password: Yup.string().required('Please enter password'),
  confirmPassword: Yup.string().required('Password mismatch'),
  userType: Yup.string().required('User Type is required'),
  email: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: (userType: USER_TYPE) =>
        userType !== USER_TYPE.SPOUSE && userType !== USER_TYPE.DEPENDENT,
      then: Yup.string().email('Please enter a valid email address').required('Email is required'),
    }),
  lastName: Yup.string().required('Last Name is required'),
  firstName: Yup.string().required('First Name is required'),
  dob: wrapIsClaimantSchema(Yup.date().required('Date of Birth is required').nullable()),

  adjustingServicesName: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_TYPE.ADJUSTER,
      then: Yup.string().required('Adjusting Services Name is required'),
    }),
  adminServicesName: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_TYPE.THIRD_PARTY_ADMIN,
      then: Yup.string().required('Administrator Services Name is required'),
    }),
  adminServices: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_TYPE.THIRD_PARTY_ADMIN,
      then: Yup.string().required('Administrator Services is required'),
    }),
  adjusterId: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_TYPE.ADJUSTER,
      then: Yup.string()
        .required('Adjuster ID is required')
        .numberOnly()
        .min(4, 'Please enter a 4-digit Adjuster ID'),
    }),
  healthCareContractorName: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_TYPE.CONTRACTOR,
      then: Yup.string().required('Health Care Contractor Name is required'),
    }),
  insuranceCarrierName: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_TYPE.CARRIER,
      then: Yup.string().required('Insurance Carrier Name is required'),
    }),
  physicianName: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_CLASSIFICATION, {
      is: USER_CLASSIFICATION.HEALTH_CARE_PROVIDER,
      then: Yup.string().required('Physician Name is required'),
    }),
  address: wrapIsClaimantOrLegalGuardianSchema(Yup.string().required('Address is required')),
  state: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string()
      .required('State is required')
      .letterOnly()
      .length(2, 'Please enter a 2-letter State')
  ),
  mailingState: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string().letterOnly().length(2, 'Please enter a 2-letter State')
  ),
  city: wrapIsClaimantOrLegalGuardianSchema(Yup.string().required('City is required')),
  zipCode: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string()
      .required('Zip Code is required')
      .min(5, 'Invalid format. Please input valid digits of zip code.')
      .max(9, 'Invalid format. Please input valid digits of zip code.')
      .numberOnly('Invalid format. Please input valid digits of zip code.')
  ),
  mailingZipCode: Yup.string()
    .nullable()
    .min(5, 'Invalid format. Please input valid digits of zip code.')
    .max(9, 'Invalid format. Please input valid digits of zip code.')
    .numberOnly('Invalid format. Please input valid digits of zip code.'),

  phoneNumber: wrapIsClaimantSchema(Yup.string().phone().required('Phone Number is required')),
  identificationType: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string().required('Identification Type is required')
  ),
  relationToClaimant: wrapIsDependentOrSpouseSchema(
    Yup.string().required('Relation to Claimant is required')
  ),

  dsFirstName: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.string().required('First Name is required')
  ),
  dsLastName: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.string().required('Last Name is required')
  ),
  dsBirthDate: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.date().required('Date of Birth is required').nullable()
  ),
  dsPhoneNumber: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.string().phone().required('Phone Number is required')
  ),
  dsEmailAddress: wrapIsDependentOrSpouseOrLegalGuardianSchema(
    Yup.string().email('Please enter a valid email address').required('Email Address is required')
  ),
  dsIdNumber: wrapIsDependentOrSpouseSchema(
    Yup.string()
      .required('Social Security Number is required')
      .length(11, 'Please enter a 9-digit Social Security Number')
  ),
  claimantEmail: wrapIsDependentOrSpouseSchema(
    Yup.string().email('Please enter a valid email address').required('Email Address is required')
  ),
  identificationNumber: wrapIsClaimantOrLegalGuardianSchema(
    Yup.string()
      .required('Identification Number is required')
      .when(SignUpFormKey.IDENTIFICATION_TYPE, {
        is: IDENTIFICATION_TYPE.SOCIAL_SECURITY_NUMBER,
        then: Yup.string().length(9, 'Please enter a 9-digit Identification Number'),
        otherwise: Yup.string()
          .bothLetterAndNumber('Please enter a 11-alphanumeric identification number')
          .length(11, 'Please enter a 11-alphanumeric identification number'),
      })
  ),
  natureOfBusiness: wrapIsSelfInsuredOrEmployer(
    Yup.string().nullable().required('Nature of Business is required')
  ),
  organizationType: wrapIsSelfInsuredOrEmployer(
    Yup.string().nullable().required('Organization Type is required')
  ),
  federalIDNumber: Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_TYPE, SignUpFormKey.USER_CLASSIFICATION], {
      is: (userType: USER_TYPE, userClassification: USER_CLASSIFICATION) =>
        userType === USER_TYPE.CONTRACTOR ||
        userClassification === USER_CLASSIFICATION.EMPLOYER ||
        userClassification === USER_CLASSIFICATION.INSURANCE ||
        userClassification === USER_CLASSIFICATION.HEALTH_CARE_PROVIDER,
      then: Yup.string()
        .required('Federal ID Number is required')
        .min(10, 'Please enter a 9-digit Federal ID Number'),
    }),
  contactNumber: Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_TYPE, SignUpFormKey.USER_CLASSIFICATION], {
      is: (userType: USER_TYPE, userClassification: USER_CLASSIFICATION) =>
        !(
          userType === USER_TYPE.EMPLOYEE ||
          userType === USER_TYPE.DEPENDENT ||
          userType === USER_TYPE.SPOUSE ||
          userType === USER_TYPE.SELF_INSURED ||
          userType === USER_TYPE.CONTRACTOR ||
          userClassification === USER_CLASSIFICATION.EMPLOYER
        ),
      then: Yup.string().phone().required('Contact Number is required'),
    }),
  organizationContactNumber: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: (userType: USER_TYPE) =>
        userType === USER_TYPE.CARRIER ||
        userType === USER_TYPE.ADJUSTER ||
        userType === USER_TYPE.THIRD_PARTY_ADMIN ||
        userType === USER_TYPE.CONTRACTOR,
      then: Yup.string().phone().required('Contact Number is required'),
    }),
  adjustingServices: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_TYPE.ADJUSTER,
      then: Yup.string().required('Services is required'),
    }),
  serviceType: Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_CLASSIFICATION, SignUpFormKey.USER_TYPE], {
      is: (userClassification: USER_CLASSIFICATION, userType: USER_TYPE) =>
        userClassification === USER_CLASSIFICATION.HEALTH_CARE_PROVIDER ||
        userType === USER_TYPE.BILL_REVIEW ||
        userType === USER_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR ||
        userClassification === USER_CLASSIFICATION.GOVERNMENT_AGENCY ||
        userClassification === USER_CLASSIFICATION.LEGAL,
      then: Yup.string().required('Type of Service is required'),
    }),
  islandOfService: Yup.array()
    .nullable()
    .when([SignUpFormKey.USER_CLASSIFICATION, SignUpFormKey.USER_TYPE], {
      is: (userClassification: USER_CLASSIFICATION, userType: USER_TYPE) =>
        userType === USER_TYPE.BILL_REVIEW ||
        userType === USER_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR ||
        userClassification === USER_CLASSIFICATION.LEGAL,
      then: Yup.array().min(1, 'Island(s) of Service/Practice is required.'),
    }),
  registrationExpirationDate: Yup.date()
    .nullable()
    .when([SignUpFormKey.USER_CLASSIFICATION, SignUpFormKey.SERVICE_TYPE], {
      is: (userClassification: USER_CLASSIFICATION, serviceType: LEGAL_SERVICE_TYPE) =>
        serviceType === LEGAL_SERVICE_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR &&
        (userClassification === USER_CLASSIFICATION.REPRESENTATIVE ||
          userClassification === USER_CLASSIFICATION.LEGAL),
      then: Yup.date().required('Registration Expiration Date is required').nullable(),
    }),
  agencyName: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_CLASSIFICATION, {
      is: USER_CLASSIFICATION.GOVERNMENT_AGENCY,
      then: Yup.string().required('Agency Name is required'),
    }),
  eofStreet: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_CLASSIFICATION, {
      is: USER_CLASSIFICATION.GOVERNMENT_AGENCY,
      then: Yup.string().required('Street is required'),
    }),
  healthCareContractor: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_CLASSIFICATION.CONTRACTOR,
      then: Yup.string()
        .required('Health Care Contractor ID is required')
        .numberOnly()
        .length(4, 'Please enter a 4-digit Health Care Contractor ID'),
    }),
  eofAddress: Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_TYPE, SignUpFormKey.USER_CLASSIFICATION], {
      is: (userType: USER_TYPE, userClassification: USER_CLASSIFICATION) =>
        userType === USER_TYPE.EMPLOYEE ||
        userType === USER_TYPE.SPOUSE ||
        userType === USER_TYPE.DEPENDENT ||
        userClassification === USER_CLASSIFICATION.EMPLOYER ||
        userType === USER_TYPE.CARRIER ||
        userType === USER_TYPE.ADJUSTER ||
        userType === USER_TYPE.THIRD_PARTY_ADMIN ||
        userType === USER_TYPE.SELF_INSURED ||
        userType === USER_TYPE.CONTRACTOR ||
        userClassification === USER_CLASSIFICATION.HEALTH_CARE_PROVIDER ||
        userType === USER_TYPE.BILL_REVIEW ||
        userType === USER_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR ||
        userClassification === USER_CLASSIFICATION.LEGAL,
      then: Yup.string().required('Address is required'),
    })
    .when(SignUpFormKey.USER_CLASSIFICATION, {
      is: USER_CLASSIFICATION.HEALTH_CARE_PROVIDER,
      then: Yup.string().required('Office Address is required'),
    }),
  eofCity: wrapEOFCity(Yup.string().required('City is required')),
  eofState: wrapEOFCity(
    Yup.string()
      .required('State is required')
      .letterOnly()
      .length(2, 'Please enter a 2-letter State')
  ),
  eofZipCode: Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_TYPE, SignUpFormKey.USER_CLASSIFICATION], {
      is: (userType: USER_TYPE, userClassification: USER_CLASSIFICATION) =>
        userType === USER_TYPE.EMPLOYEE ||
        userType === USER_TYPE.SPOUSE ||
        userType === USER_TYPE.DEPENDENT ||
        userClassification === USER_CLASSIFICATION.EMPLOYER ||
        userType === USER_TYPE.CARRIER ||
        userType === USER_TYPE.ADJUSTER ||
        userType === USER_TYPE.THIRD_PARTY_ADMIN ||
        userType === USER_TYPE.SELF_INSURED ||
        userType === USER_TYPE.BILL_REVIEW ||
        userType === USER_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR ||
        userClassification === USER_CLASSIFICATION.LEGAL ||
        userType === USER_TYPE.CONTRACTOR ||
        userClassification === USER_CLASSIFICATION.GOVERNMENT_AGENCY,
      then: Yup.string()
        .required('Zip Code is required')
        .min(5, 'Invalid format. Please input valid digits of zip code.')
        .max(9, 'Invalid format. Please input valid digits of zip code.')
        .numberOnly('Invalid format. Please input valid digits of zip code.'),
    }),
  eofName: Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_TYPE, SignUpFormKey.USER_CLASSIFICATION], {
      is: (userType: USER_TYPE, userClassification: USER_CLASSIFICATION) =>
        userType === USER_TYPE.EMPLOYEE ||
        userType === USER_TYPE.SPOUSE ||
        userType === USER_TYPE.DEPENDENT ||
        userClassification === USER_CLASSIFICATION.EMPLOYER ||
        userType === USER_TYPE.SELF_INSURED ||
        userType === USER_TYPE.BILL_REVIEW ||
        userType === USER_TYPE.VOCATIONAL_REHABILITATION_COUNSELOR ||
        userClassification === USER_CLASSIFICATION.LEGAL,
      then: Yup.string().required('Name is required'),
    }),
  eofPhoneNumber: Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_TYPE, SignUpFormKey.USER_CLASSIFICATION], {
      is: (userType: USER_TYPE, userClassification: USER_CLASSIFICATION) =>
        userClassification === USER_CLASSIFICATION.EMPLOYER || userType === USER_TYPE.SELF_INSURED,
      then: Yup.string().phone().required('Phone Number is required'),
    })
    .when(SignUpFormKey.USER_CLASSIFICATION, {
      is: USER_CLASSIFICATION.HEALTH_CARE_PROVIDER,
      then: Yup.string().phone().required('Office Phone Number is required'),
    }),
  eofMailingState: Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_TYPE, SignUpFormKey.USER_CLASSIFICATION], {
      is: (userType: USER_TYPE, userClassification: USER_CLASSIFICATION) =>
        userClassification === USER_CLASSIFICATION.EMPLOYER || userType === USER_TYPE.SELF_INSURED,
      then: Yup.string().nullable().letterOnly().length(2, 'Please enter a 2-letter State'),
    }),
  eofMailingZipCode: Yup.string()
    .nullable()
    .min(5, 'Invalid format. Please input valid digits of zip code.')
    .max(9, 'Invalid format. Please input valid digits of zip code.')
    .numberOnly('Invalid format. Please input valid digits of zip code.'),

  claimantIdNumber: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_TYPE.LEGAL_GUARDIAN,
      then: Yup.string()
        .required('Identification Number is required')
        .when(SignUpFormKey.CLAIMANT_ID_TYPE, {
          is: IDENTIFICATION_TYPE.SOCIAL_SECURITY_NUMBER,
          then: Yup.string().length(9, 'Please enter a 9-digit Identification Number'),
          otherwise: Yup.string()
            .bothLetterAndNumber('Please enter a 11-alphanumeric identification number')
            .length(11, 'Please enter a 11-alphanumeric identification number'),
        }),
    }),
  claimantIdType: Yup.string()
    .nullable()
    .when(SignUpFormKey.USER_TYPE, {
      is: USER_TYPE.LEGAL_GUARDIAN,
      then: Yup.string().required('Identification Type is required'),
    }),

  eofSelfInsuredOrAdjustingCompany: Yup.string()
    .nullable()
    .when([SignUpFormKey.USER_TYPE, SignUpFormKey.IS_THIRD_PARTY_ADMIN], {
      is: (userType: USER_TYPE, isTPA: boolean) => userType === USER_TYPE.ADJUSTER && isTPA,
      then: Yup.string().required('Complete this field.'),
    }),
});
