/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IMAGES } from 'src/appConfig/images';
import { PATHS } from 'src/appConfig/paths';
import {
  Button,
  Form,
  Grid,
  Image,
  Input,
  InputPassword,
  NavLink,
  Text,
  View,
} from 'src/components/common';
import { SignInPayload, useLogin, useProfile, useResendSignUp } from 'src/queries';
import { hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
import { IRootState } from 'src/redux/rootReducer';
import { ErrorService, Navigator, Yup } from 'src/services';
import configs from 'src/appConfig';
import '../../Signin/styles.scss';

import EmailConfirmationModal from '../../common/EmailConfirmationModal';
import './styles.scss';
import { UAMBody } from '../../common';

type FormValue = {
  email: string;
  password: string;
};

const INITIAL: FormValue = { email: '', password: '' };

const Signin: React.FC<Props> = ({ onShowDialog, onHideDialog }) => {
  const [loginSuccess, setLoginSuccess] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type') || '';

  const IS_MAINTENANCE = false;

  const formRef = useRef<FormikProps<FormValue>>(null);
  const reRef = useRef<ReCAPTCHA>();

  const { login, isSigning } = useLogin({
    onSuccess(data, variables, context) {
      setLoginSuccess(true);
      query.set('type', 'redirect');
      history.push({ search: query.toString() });
    },
    onError(error, variables, context) {
      handleError(error, variables);
    },
  });
  const { loading } = useProfile();

  const handleLogin = async (values: FormValue) => {
    const recaptchaToken = await reRef.current.executeAsync();
    reRef.current.reset();

    const { email, password } = values;
    const username = `${email.trim().toLowerCase()}`;

    login({ username, password, recaptchaToken });
  };

  React.useEffect(() => {
    if (type.includes('logout')) {
      query.delete('type');
      history.push({ search: query.toString() });
    }
  }, []);

  const { resendSignUp } = useResendSignUp();

  const handleConfirmSuccess = async (payload: Omit<SignInPayload, 'recaptchaToken'>) => {
    onHideDialog();

    const recaptchaToken = await reRef.current.executeAsync();
    reRef.current.reset();

    login({ ...payload, recaptchaToken });
  };

  const handleError = (error: AuthError, variables: SignInPayload) => {
    switch (error.code) {
      case ErrorService.TYPES.NotAuthorizedException:
        return formRef.current.setErrors({
          email: '  ',
          password: ErrorService.MESSAGES.incorrectCredentials,
        });

      case ErrorService.TYPES.UserNotFoundException:
        return formRef.current.setErrors({ email: ErrorService.MESSAGES.accountNotExist });
      case ErrorService.TYPES.UserNotConfirmedException:
        resendSignUp(
          { username: variables.username },
          {
            onSuccess(data) {
              onShowDialog({
                type: DIALOG_TYPES.CONTENT_DIALOG,
                data: {
                  content: (
                    <EmailConfirmationModal
                      username={variables.username}
                      onConfirmSuccess={() =>
                        handleConfirmSuccess({
                          username: variables.username,
                          password: variables.password,
                        })
                      }
                    />
                  ),
                  hideTitle: true,
                },
              });
            },
          }
        );
        return;
      case ErrorService.TYPES.UsernameExistsException:
        return;

      default:
        return ErrorService.handler(error);
    }
  };

  // =========================== FORGOT PASSWORD ===========================
  const handleForgotPassword = (data: FormValue) => {
    Navigator.navigate(PATHS.forgotPassword, { email: data.email });
  };

  // =========================== SCHEMA ===========================
  const SigninSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    password: Yup.string().required(),
  });

  return (
    <>
      {loginSuccess && (
        <Backdrop
          open={true}
          classes={{
            root: 'muibackdrop-root',
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {IS_MAINTENANCE ? (
        <View className="maintenance-container" justify="center" align="center">
          <Image src={IMAGES.serviceNotAvailable} />
          <Text className="pt-16" size={24}>
            The system is currently under maintenance.
          </Text>
          <Text className="pt-16" size={24}>
            Please check back later.
          </Text>
        </View>
      ) : (
        <UAMBody>
          <Formik
            initialValues={INITIAL}
            onSubmit={handleLogin}
            validationSchema={SigninSchema}
            innerRef={formRef}
          >
            {({ values, errors, touched, getFieldProps, handleSubmit }) => (
              <Form onSubmit={handleSubmit} autoComplete="off" className="ctn-uam__form">
                <Grid.Wrap>
                  <Grid.Item variant="is-full">
                    <Input
                      label="Email Address"
                      required
                      placeholder="Email Address"
                      errorMessage={touched.email ? errors.email : ''}
                      {...getFieldProps('email')}
                    />
                  </Grid.Item>
                  <Grid.Item variant="is-full">
                    <InputPassword
                      label="Password"
                      required
                      placeholder="Password"
                      errorMessage={touched.password ? errors.password : ''}
                      {...getFieldProps('password')}
                    />
                    <Text size={14} className="has-text-primary mt-4">
                      Important! Keep your user name and password private. Sharing your account
                      email and password with unauthorized parties could compromise your security or
                      the security of your business and reveal sensitive information about you or
                      your clients.
                    </Text>
                  </Grid.Item>
                  <Grid.Item variant="is-full">
                    <View flexGrow={1} isRowWrap justify="flex-end">
                      <Button
                        type="button"
                        variant="link"
                        className="ctn-uam__link text-is-16 my-1 fw-medium fit-width"
                        onClick={() => handleForgotPassword(values)}
                      >
                        Forgot Password?
                      </Button>
                    </View>
                  </Grid.Item>
                  <ReCAPTCHA sitekey={configs.RECAPTCHA_SITE_KEY} size="invisible" ref={reRef} />
                  <Grid.Item variant="is-full">
                    <View flexGrow={1}>
                      <Button
                        type="submit"
                        variant="secondary"
                        className="my-2 fw-medium"
                        isLoading={isSigning || loading}
                      >
                        Log In
                      </Button>
                    </View>
                  </Grid.Item>
                  <Grid.Item variant="is-full">
                    <View flexGrow={1}>
                      <Text className="text-center my-2" size={16}>
                        Don’t have account?
                        <NavLink className={'fw-medium ml-1 text-is-16'} to={PATHS.signUp}>
                          Create Account
                        </NavLink>
                      </Text>
                    </View>
                  </Grid.Item>
                </Grid.Wrap>
              </Form>
            )}
          </Formik>
        </UAMBody>
      )}
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & { history: History };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
