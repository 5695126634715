/* eslint-disable react-hooks/exhaustive-deps */
import cn from 'classnames';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Location } from 'history';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Grid,
  IconSuccess,
  InputPassword,
  NavLink,
  PasswordSharingMessage,
  Text,
  ValidatePassword,
  View,
} from 'src/components/common';
import Form from 'src/components/common/Form';
import { IRootState } from 'src/redux/rootReducer';

import { PATHS } from 'src/appConfig/paths';
import { ErrorService, Navigator, Toastify, Yup } from 'src/services';

import ComfirmationCodeField from 'src/components/ComfirmationCodeField';
import { useForgotPassword, useSubmitForgotPassword } from 'src/queries';
import { UAMBody } from '../../common';
import '../../Signin/styles.scss';

type FormValue = {
  email: string;
  password: string;
  confirmPassword: string;
  code: string;
};

const INITIAL = { password: '', confirmPassword: '', email: '', code: '' };

const ResetPassword: React.FC<Props> = ({ location }) => {
  // const query = new URLSearchParams(location.search);
  const query = new URLSearchParams(location.search);

  const formRef = useRef<FormikProps<FormValue>>(null);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

  const { submitForgotPassword, isLoading } = useSubmitForgotPassword({
    onSuccess(data, variables, context) {
      setIsPasswordUpdated(true);
    },
    onError(error, variables, context) {
      ErrorService.handler(error);
    },
  });

  useEffect(() => {
    // Check for query params "email" and "token". Should be included in link sent to email from forgot password submission.
    if (!query.has('email')) {
      Navigator.navigate(PATHS.forgotPassword);
    }
  }, []);

  // =========================== RESET PASSWORD ===========================
  const handleResetPassword = (values: FormValue, helpers: FormikHelpers<FormValue>) => {
    const { password, confirmPassword } = values;

    // eslint-disable-next-line security/detect-possible-timing-attacks
    if (password !== confirmPassword) {
      formRef.current.setErrors({ confirmPassword: 'Password and Confirm Password do not match.' }); // pragma: allowlist secret
      return;
    } else {
      const body = {
        email: query.get('email'),
        password: password,
        token: values?.code,
      };
      return submitForgotPassword(body);
    }
  };

  const { forgotPassword, isLoading: isLoadingForgotPassword } = useForgotPassword({
    onSuccess(data, variables, context) {
      Toastify.success('A new link has been sent to your email.');
    },
    onError(error, variables, context) {
      ErrorService.handler(error);
    },
  });

  // =========================== FORGOT PASSWORD ===========================
  const handleResendForgotPassword = () => {
    const email = query.get('email');
    forgotPassword({ email: email });
  };

  const handleBackToLogin = () => {
    Navigator.navigate(PATHS.signIn);
  };

  // =========================== SCHEMA ===========================
  const ResetSchema = Yup.object().shape({
    password: Yup.string().required().password(),
    code: Yup.string().min(6).required(),
  });

  return (
    <UAMBody>
      {isPasswordUpdated ? (
        <View isRow justify="center" align="center" className="mb-24">
          <IconSuccess size={46} />
          <h1 className={cn('ctn-uam__title ml-16')}>{'Password Updated'}</h1>
        </View>
      ) : (
        <>
          <Text
            size={32}
            className={cn('fw-medium mb-16 text-color-grey-900 text-align-center')}
            isTranslatable
          >
            Reset Password
          </Text>
          <Text className="mb-24 text-align-center">
            Check your email and enter your verification code.
          </Text>
        </>
      )}

      {isPasswordUpdated ? (
        <>
          <Text className={cn('mb-36 text-align-center')}>
            {'Your password has been successfully updated.'}
          </Text>
          <Button onClick={handleBackToLogin} variant="secondary" className="mb-8">
            Log In
          </Button>
        </>
      ) : (
        <Formik
          initialValues={INITIAL}
          onSubmit={handleResetPassword}
          validationSchema={ResetSchema}
          innerRef={formRef}
        >
          {({ values, errors, touched, setFieldValue, getFieldProps, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit} autoComplete="off" className="ctn-uam__form">
                <Grid.Wrap>
                  <Grid.Item variant="is-full">
                    <ComfirmationCodeField
                      onChange={(value) => setFieldValue('code', value)}
                      errorMessage={touched.code ? errors.code : ''}
                    />
                  </Grid.Item>
                  <Grid.Item variant="is-full">
                    <InputPassword
                      label="New Password"
                      required
                      placeholder="New Password"
                      errorMessage={touched.password ? errors.password : ''}
                      {...getFieldProps('password')}
                    />
                    <ValidatePassword className="" password={values?.password} />
                    <PasswordSharingMessage />
                  </Grid.Item>
                  <Grid.Item variant="is-full">
                    <InputPassword
                      label="Confirm Password"
                      required
                      placeholder="Confirm Password"
                      errorMessage={touched.confirmPassword ? errors.confirmPassword : ''}
                      {...getFieldProps('confirmPassword')}
                    />
                  </Grid.Item>
                  <Grid.Item variant="is-full">
                    <View flexGrow={1}>
                      <Button
                        type="submit"
                        variant="secondary"
                        className="my-12 fw-medium"
                        isLoading={isLoading}
                      >
                        Reset
                      </Button>
                    </View>
                  </Grid.Item>
                </Grid.Wrap>
              </Form>
            );
          }}
        </Formik>
      )}

      {!isPasswordUpdated && (
        <>
          <View isRow align="center" justify="center" className="mb-20 ">
            <Text className="mr-8" size={16}>
              Didn't receive the code?
            </Text>
            <Button
              variant="link"
              isLoading={isLoadingForgotPassword}
              onClick={() => handleResendForgotPassword()}
            >
              Resend email
            </Button>
          </View>
          <Text className="my-2 fw-medium text-center">
            <NavLink to={PATHS.signIn} className="text-is-16">
              Back to Log In
            </NavLink>
          </Text>
        </>
      )}
    </UAMBody>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & { location: Location<string> };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
