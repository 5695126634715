import { FieldInputProps } from 'formik';
import React from 'react';
import { DatePicker, Grid, Input, InputMask, InputPhone, Select } from 'src/components/common';
import { Callback } from 'src/redux/types';
import ClaimantAddress from '../../EmployeeQuestion/ClaimantAddress';
import SectionText from '../../SectionText';
import {
  IDENTIFICATION_TYPE,
  SignUpFormKey,
  SignupFormValues,
  identificationTypeOptions,
} from '../../helpers';

const ClaimantInformation: React.FC<Props> = ({
  getFieldProps,
  setFieldValue,
  values,
  getErrorMessage,
  setFieldTouched,
  handleChangeState,
}) => {
  const handleIdentificationTypeChange = (name, value) => {
    setFieldValue(name, value);
    setFieldValue(SignUpFormKey.CLAIMANT_ID_NUMBER, '');
    setFieldTouched(SignUpFormKey.CLAIMANT_ID_NUMBER, false);
  };

  return (
    <>
      <SectionText section="Claimant's Personal Information" />
      <Grid.Item>
        <Input
          label={'Last Name'}
          required
          placeholder="Enter your Last Name"
          errorMessage={getErrorMessage(SignUpFormKey.DS_LAST_NAME)}
          maxLength={40}
          {...getFieldProps(SignUpFormKey.DS_LAST_NAME)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Middle Name`}
          placeholder="Enter your Middle Name"
          errorMessage={getErrorMessage(SignUpFormKey.DS_MIDDLE_NAME)}
          {...getFieldProps(SignUpFormKey.DS_MIDDLE_NAME)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={'First Name'}
          required
          placeholder="Enter your First Name"
          errorMessage={getErrorMessage(SignUpFormKey.DS_FIRST_NAME)}
          maxLength={30}
          {...getFieldProps(SignUpFormKey.DS_FIRST_NAME)}
        />
      </Grid.Item>
      <Grid.Item>
        <DatePicker
          label={`Date of Birth`}
          errorMessage={getErrorMessage(SignUpFormKey.DS_BIRTHDAY)}
          {...getFieldProps(SignUpFormKey.DS_BIRTHDAY)}
          selected={values?.[SignUpFormKey.DS_BIRTHDAY] as Date}
          onChange={setFieldValue}
          placeholder={'MM/DD/YYYY'}
          maxDate={new Date()}
          required
        />
      </Grid.Item>
      <Grid.Item>
        <Select
          label={'Identification Type'}
          options={identificationTypeOptions}
          errorMessage={getErrorMessage(SignUpFormKey.CLAIMANT_ID_TYPE)}
          {...getFieldProps(SignUpFormKey.CLAIMANT_ID_TYPE)}
          onChange={handleIdentificationTypeChange}
          isClearable={false}
          required
          onBlur={setFieldTouched}
        />
      </Grid.Item>
      <Grid.Item>
        <InputMask
          label={`Identification Number`}
          mask={
            values[SignUpFormKey.CLAIMANT_ID_TYPE] === IDENTIFICATION_TYPE.SOCIAL_SECURITY_NUMBER
              ? '999999999'
              : '***********'
          }
          placeholder="Enter your Identification Number"
          required
          disabled={!values[SignUpFormKey.CLAIMANT_ID_TYPE]}
          errorMessage={getErrorMessage(SignUpFormKey.CLAIMANT_ID_NUMBER)}
          {...getFieldProps(SignUpFormKey.CLAIMANT_ID_NUMBER)}
        />
      </Grid.Item>
      <Grid.Item>
        <InputPhone
          international={false}
          countries={['US']}
          label={`Phone Number`}
          placeholder={'Enter your Phone Number'}
          {...getFieldProps(SignUpFormKey.DS_PHONE_NUMBER)}
          errorMessage={getErrorMessage(SignUpFormKey.DS_PHONE_NUMBER)}
          onChange={setFieldValue}
          required
        />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <Input
          label={'Email Address'}
          required
          placeholder={`Enter Email Address`}
          {...getFieldProps(SignUpFormKey.DS_EMAIL_ADDRESS)}
          errorMessage={getErrorMessage(SignUpFormKey.DS_EMAIL_ADDRESS)}
          containerClassName="cmp-email-container"
        />
      </Grid.Item>
      <ClaimantAddress
        getFieldProps={getFieldProps}
        getErrorMessage={getErrorMessage}
        handleChangeState={handleChangeState}
      />
    </>
  );
};

type Props = {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  setFieldValue: Callback;
  values: SignupFormValues;
  getErrorMessage: (fieldName: SignUpFormKey) => string;
  setFieldTouched: Callback;
  handleChangeState: Callback;
};

export default ClaimantInformation;
