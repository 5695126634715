import { FieldInputProps } from 'formik';
import React from 'react';
import { Grid, Input } from 'src/components/common';
import { SignUpFormKey } from '../../helpers';
import { Callback } from 'src/redux/types';

const AddressSection: React.FC<Props> = ({ getFieldProps, getErrorMessage, handleChangeState }) => {
  return (
    <>
      <Grid.Item>
        <Input
          label={`Address`}
          required
          placeholder={`Enter your Address`}
          errorMessage={getErrorMessage(SignUpFormKey.ADDRESS)}
          {...getFieldProps(SignUpFormKey.ADDRESS)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`City`}
          required
          placeholder={`Enter your City`}
          errorMessage={getErrorMessage(SignUpFormKey.CITY)}
          {...getFieldProps(SignUpFormKey.CITY)}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`State`}
          required
          placeholder={`Enter your State`}
          errorMessage={getErrorMessage(SignUpFormKey.STATE)}
          {...getFieldProps(SignUpFormKey.STATE)}
          onChange={handleChangeState}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          label={`Zip Code`}
          required
          errorMessage={getErrorMessage(SignUpFormKey.ZIP_CODE)}
          placeholder={`Enter your Zip Code`}
          {...getFieldProps(SignUpFormKey.ZIP_CODE)}
        />
      </Grid.Item>
    </>
  );
};

type Props = {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  getErrorMessage: (fieldName: SignUpFormKey) => string;
  prefix?: string;
  isRequired?: boolean;
  handleChangeState: Callback;
};

export default AddressSection;
