import { FieldInputProps } from 'formik';
import React from 'react';
import { Grid, RadioButton } from 'src/components/common';
import { Callback } from 'src/redux/types';
import { SignUpFormKey } from '../helpers';
import { adjusterTypeOptions } from './helpers';

const AdjusterTypeCheck: React.FC<Props> = ({ getFieldProps, getErrorMessage, setFieldValue }) => {
  return (
    <Grid.Item variant="is-full">
      <RadioButton
        label="Self-insured or Adjusting Company "
        options={adjusterTypeOptions}
        columns={1}
        errorMessage={getErrorMessage(SignUpFormKey.ADJUSTER_TYPE)}
        {...getFieldProps(SignUpFormKey.ADJUSTER_TYPE)}
        onChange={setFieldValue}
      />
    </Grid.Item>
  );
};

type Props = {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  setFieldValue: Callback;
  getErrorMessage: (fieldName: SignUpFormKey) => string;
  setFieldTouched: Callback;
};

export default AdjusterTypeCheck;
